import { ScrollView, Text, TouchableHighlight, View } from 'react-native'
import React, { useContext } from 'react'

import { ChevronRightIcon } from 'src/icons'
import { colors } from 'src/styles'
import { isGBSelector } from 'src/store/app/selectors'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { navigateToAppScreenWithoutHistory } from 'src/utils/navigateToScreen'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AnnouncementModalImage from './AnnouncementModalImage'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import getAnnouncementModalContent from './content'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  closeAnnouncementModal: () => void
  showModal: boolean
}

const AnnouncementModal = ({ closeAnnouncementModal, showModal }: Props) => {
  const isGB = useAppSelector(isGBSelector)
  const { mixpanel } = useContext(MixpanelContext)

  const {
    bodyPartOne,
    bodyPartTwo,
    icon: Icon,
    images,
    overline,
    primaryAction,
    title,
  } = getAnnouncementModalContent(isGB, () =>
    navigateToAppScreenWithoutHistory(['MainTabNavigator', 'MenuNavigation', 'Referrals']),
  )

  const primaryActionOnPress = () => {
    primaryAction.onPress && primaryAction.onPress()
    mixpanel?.track('ReferAFriendOrFoe - Referrals', { action: 'open' })
    closeAnnouncementModal()
  }

  return (
    <NewModalWrapper
      backdropOpacity={0.5}
      containerStyle={styles.modalContent}
      isVisible={showModal}
      onBackdropPress={closeAnnouncementModal}
      onSwipeComplete={closeAnnouncementModal}
      propagateSwipe
      style={styles.modal}
      swipeDirection={['down']}
    >
      <View style={styles.container}>
        <View style={styles.divider} />
        <View style={styles.overlineWrapper}>
          <Text style={styles.overline}>{overline}</Text>
          <Icon fill={colors.black} size={19} />
        </View>
        <Text style={styles.title}>{title}</Text>
        <ScrollView scrollEnabled showsVerticalScrollIndicator style={styles.mainContent}>
          <TouchableHighlight>
            <>
              <Text style={styles.mainText}>{bodyPartOne}</Text>
              <Text style={styles.mainText}>{bodyPartTwo}</Text>
              {images.map((uri, index) => (
                <AnnouncementModalImage uri={uri} key={index} />
              ))}
            </>
          </TouchableHighlight>
        </ScrollView>
        <View style={styles.buttonWrapper}>
          {primaryAction.onPress ? (
            <ButtonNew
              isLong
              onPress={closeAnnouncementModal}
              title={translate(translations.close)}
              variant={buttonVariants.outlinedDefault}
            />
          ) : null}
          <View style={styles.buttonSpace} />
          <ButtonNew
            isLong
            onPress={primaryActionOnPress}
            RightIcon={ChevronRightIcon}
            title={primaryAction.onPress ? primaryAction.title : translate(translations.close)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
    </NewModalWrapper>
  )
}

export default AnnouncementModal

import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const headerText = {
  textAlign: 'center',
  width: '100%',
}

const styles = StyleSheet.create({
  buttonsWrapper: {
    marginHorizontal: paddingNew.lg,
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginHorizontal: paddingNew.sm,
    marginTop: paddingNew.md,
  },
  headerWrapper: {
    alignItems: 'center',
    gap: paddingNew.xs,
    marginBottom: paddingNew.md,
    marginHorizontal: paddingNew.sm,
    marginTop: paddingNew.sm,
  },
  info: {
    ...headerText,
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  redirectsRowsWrapper: {
    marginBottom: paddingNew.md,
    marginHorizontal: paddingNew.sm,
    marginTop: paddingNew.base,
  },
  subInfo: {
    ...headerText,
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles

import { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'

import { isSESelector } from 'src/store/app/selectors'
import { updateNewMessageText } from 'src/store/inbox/actions'

import ChatQuery from 'src/graphql/Chat/index'

const REFRESH_INTERVAL = 30
const INACTIVE_REFRESH = 10000

const useChatMessage = () => {
  const id = useSelector(state => state?.user?.id)
  const isSE = useSelector(isSESelector)
  const currentNewMessage = useSelector(state => state?.inbox?.newMessage)
  const shouldPullMessages = useSelector(state => state?.inbox?.shouldPullMessages)

  const dispatch = useDispatch()

  const { data, refetch } = useQuery(ChatQuery, {
    variables: {
      userId: id,
    },
    skip: !id || isSE,
    notifyOnNetworkStatusChange: true,
    pollInterval: (shouldPullMessages ? REFRESH_INTERVAL : INACTIVE_REFRESH) * 1000,
  })

  const newMessage = useMemo(() => {
    const messagesList = data?.messages?.nodes || []
    const unreadMessage = messagesList.find(message => message?.senderId !== id && message?.read === false)
    return unreadMessage ? unreadMessage.content.slice(0, 50) : null
  }, [data, id])

  useEffect(() => {
    if (!isSE) {
      currentNewMessage !== newMessage && dispatch(updateNewMessageText(newMessage))
    }
  }, [dispatch, newMessage, currentNewMessage, isSE])

  const isNewMessage = !!newMessage

  return { isNewMessage, refetch }
}

export default useChatMessage

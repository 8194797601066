import { Platform, StyleSheet } from 'react-native'

import { colorsNew, fontSizesNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  shadow: {
    backgroundColor: colorsNew.white,
    borderRadius: 8,
    elevation: 2,
    margin: paddingNew.xxs,
    marginTop: paddingNew.sm,
    shadowColor: colorsNew.black,
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 1,
  },
  container: {
    overflow: 'hidden',
    paddingBottom: paddingNew.sm,
  },
  userInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: paddingNew.sm,
    paddingBottom: 0,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
  iconContainer: {
    justifyContent: 'flex-start',
  },
  icon: {
    color: colorsNew.brand.secondaryDark,
  },
  expandedContainer: {
    position: 'absolute',
  },
  expandedText: {
    color: colorsNew.brand.secondaryDark,
    fontSize: fontSizesNew.xxs.size,
    fontWeight: '600',
    lineHeight: fontSizesNew.xxs.lh,
    paddingTop: paddingNew.sm,
    paddingHorizontal: paddingNew.sm,
  },
  question: {
    color: colorsNew.brand.secondaryDark,
    flex: 1,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
    lineHeight: fontSizesNew.xs.lh,
    paddingRight: paddingNew.md,
  },
  link: {
    color: colorsNew.info,
  },
})

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Animated, Text, View } from 'react-native'

import styles from './styles'

export default class SelectableText extends PureComponent {
  static propTypes = {
    animated: PropTypes.bool,
    children: PropTypes.node,
    flexible: PropTypes.bool,
    style: PropTypes.any,
  }

  render() {
    const { children, style, flexible, animated, ...textProps } = this.props
    const TextComponent = animated ? Animated.Text : Text

    return (
      <View style={flexible && styles.flexible}>
        <TextComponent selectable style={[global.isIOS && styles.text, style]} {...textProps}>
          {children}
        </TextComponent>
      </View>
    )
  }
}

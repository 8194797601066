import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React, { useMemo } from 'react'

import { AlertColorScheme, AlertVariants } from './types'
import { CheckCircleOutlinedIcon, CloseIcon, ErrorOutlinedIcon, InfoOutlinedIcon, WarningAmberIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { getAdditionalActionButtonVariant } from './utils'
import ButtonNew, { ButtonNewProps, buttonSize } from 'src/components/Buttons/ButtonNew'
import styles, { getCardColor, getTextColor, iconColor } from './styles'

export type AlertCardProps = {
  additionalAction?: Omit<ButtonNewProps, 'variant'>
  colorSchema: AlertColorScheme
  description?: string
  id?: string
  onClose?: () => void
  testId?: string
  title?: string
  variant: AlertVariants
}

const AlertCard = ({ additionalAction, colorSchema, variant, title, description, onClose, testId }: AlertCardProps) => {
  const Icon = useMemo(() => {
    switch (colorSchema) {
      case 'error':
        return ErrorOutlinedIcon
      case 'info':
        return InfoOutlinedIcon
      case 'success':
        return CheckCircleOutlinedIcon
      case 'warning':
        return WarningAmberIcon
    }
  }, [colorSchema])

  const additionalActionButtonProps = useMemo(() => {
    if (!additionalAction) return

    const additionalActionButtonVariant = getAdditionalActionButtonVariant(colorSchema, variant)

    return {
      ...additionalAction,
      backgroundTransparent: additionalActionButtonVariant.includes('outlined'),
      isShort: true,
      size: buttonSize.sm,
      variant: additionalActionButtonVariant,
    }
  }, [additionalAction, colorSchema, variant])

  return (
    <View style={StyleSheet.flatten([styles.card, getCardColor(variant, colorSchema)])} testID={testId}>
      <Icon fill={iconColor[colorSchema][variant]} size={22} />
      <View style={styles.content}>
        {title ? (
          <Text style={StyleSheet.flatten([styles.title, getTextColor(variant, colorSchema)])}>{title}</Text>
        ) : null}
        {description ? (
          <Text style={StyleSheet.flatten([styles.description, getTextColor(variant, colorSchema)])}>
            {description}
          </Text>
        ) : null}
        {additionalActionButtonProps ? <ButtonNew {...additionalActionButtonProps} style={styles.button} /> : null}
      </View>
      {onClose ? (
        <TouchableOpacity onPress={onClose} style={styles.closeButton} testID="alert-card-close">
          <CloseIcon fill={colorsNew.redesign.action.active} size={22} />
        </TouchableOpacity>
      ) : null}
    </View>
  )
}

export default AlertCard

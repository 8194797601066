import merge from 'lodash/merge'

import { deepLinkNavigateParams } from 'src/utils/navigateToScreen'

// NEED TO SETUP ALSO ON src/PrivateRoutes.tsx:46

export const profileDeepViews = ['MainTabNavigator', 'MenuNavigation', 'Menu']
const profileDeepLink = deepLinkNavigateParams(['Main', 'PrivateRoutes', 'App', ...profileDeepViews], 'profile')

const introductionDeepLink = deepLinkNavigateParams(['Main', 'PrivateRoutes', 'App', 'Introduction'], 'introduction')

export const linksConfig = merge(profileDeepLink, introductionDeepLink)

import AsyncStorage from '@react-native-async-storage/async-storage'

import { SnackBarProps, ConnectionTypes } from './index'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import { setLocale } from 'src/utils/translations/i18n'
import {
  SET_APP_IS_LOADING,
  SET_BOOKING_UNCONFIRMED,
  SET_CONNECTION_STATUS,
  SET_COUNTRY,
  SET_DEVICE_TOKEN,
  SET_HIDDEN_BOOKING_ALERT_ID,
  SET_SNACKBAR_PROPS,
  SET_TAB_OVERLAY,
} from './types'

export const setAppIsLoading = (isLoading: boolean) => ({ type: SET_APP_IS_LOADING, payload: isLoading } as const)

export const setDeviceToken = (deviceToken: string | null) => {
  deviceToken && AsyncStorage.setItem('deviceToken', deviceToken)

  return {
    type: SET_DEVICE_TOKEN,
    payload: deviceToken,
  } as const
}

export const setSnackbarProps = (snackbarSettings: SnackBarProps) =>
  ({
    type: SET_SNACKBAR_PROPS,
    payload: snackbarSettings,
  } as const)

export const setConnectionStatus = (data: ConnectionTypes) =>
  ({
    type: SET_CONNECTION_STATUS,
    payload: data,
  } as const)

export const setBookingUnconfirmed = (isBookingUnconfirmed: boolean) =>
  ({
    type: SET_BOOKING_UNCONFIRMED,
    payload: isBookingUnconfirmed,
  } as const)

export const setCountryCode = (countryCode: LocaleT) => {
  setLocale(countryCode)

  return {
    type: SET_COUNTRY,
    payload: countryCode,
  } as const
}

export const setTabOverlay = (isOverlay: boolean) =>
  ({
    type: SET_TAB_OVERLAY,
    payload: isOverlay,
  } as const)

export const setHiddenBookingAlertId = (id: string) =>
  ({
    type: SET_HIDDEN_BOOKING_ALERT_ID,
    payload: id,
  } as const)

export type ActionTypes =
  | ReturnType<typeof setAppIsLoading>
  | ReturnType<typeof setDeviceToken>
  | ReturnType<typeof setSnackbarProps>
  | ReturnType<typeof setConnectionStatus>
  | ReturnType<typeof setBookingUnconfirmed>
  | ReturnType<typeof setCountryCode>
  | ReturnType<typeof setTabOverlay>
  | ReturnType<typeof setHiddenBookingAlertId>

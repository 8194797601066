import React, { useEffect, useRef, useState } from 'react'
import { Text, View } from 'react-native'

import { referralsStates } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import formatter from 'src/utils/formatter'
import ReferChip from 'src/scenes/Main/ReferAFriendOrFoe/components/ReferChip'
import ReferralModal from '../ReferralModal/ReferralModal'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

const ReferralRow = ({ referral, section }) => {
  const [isActionInformationVisible, setIsActionInformationVisible] = useState(false)
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false)

  const closeReferralModal = () => setIsReferralModalOpen(false)
  const openReferralModal = () => setIsReferralModalOpen(true)

  const timer = useRef<NodeJS.Timeout>()

  const isLeftHumlySection = section === referralsStates.educatorLeftHumly
  const isPaidOutSection = section === referralsStates.paidOut

  useEffect(() => {
    return () => timer.current && clearTimeout(timer.current)
  }, [])

  const handleChipOnPress = () => {
    if (referral.paid || isLeftHumlySection) {
      setIsActionInformationVisible(true)
      timer.current = setTimeout(() => setIsActionInformationVisible(false), 2000)
    } else {
      openReferralModal()
    }
  }

  const applicantAppliedDate = formatter.shortDate(referral.applied)
  const bonusPaidOutDate = formatter.shortDate(referral.bonusDate)

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.info}>{referral.applicantName}</Text>
        <Text style={styles.subInfo}>
          {isPaidOutSection
            ? translate(translations.referralPaidOutInfo, { date: bonusPaidOutDate })
            : translate(translations.referralAppliedInfo, { date: applicantAppliedDate })}
        </Text>
      </View>
      <View style={styles.chipWrapper}>
        <ReferChip
          disabled={referral.paid || isLeftHumlySection}
          onPress={handleChipOnPress}
          variant={referral.variant}
        />
        {isActionInformationVisible ? (
          <Text style={styles.actionInfo}>{translate(translations.selectionNoLongerAvailable)}</Text>
        ) : null}
      </View>
      <ReferralModal
        closeModal={closeReferralModal}
        educatorName={referral.applicantName}
        showModal={isReferralModalOpen}
      />
    </View>
  )
}

export default ReferralRow

export type FeatureFlagsT = {
  referAFriend: boolean
}

export type FeatureFlagStateT = {
  featureFlags: FeatureFlagsT
}

export enum FeatureFlags {
  FEATURE_TOGGLE = 'referAFriend',
}

export const flagKeys = [FeatureFlags.FEATURE_TOGGLE]

import React from 'react'
import { Text, View } from 'react-native'

import { getReferralSectionInfo } from 'src/scenes/Main/ReferAFriendOrFoe//utils'
import { referralsStates } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ReferralRow from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/components/ReferralRow'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

const ReferralSection = ({ section, sectionReferrals }) => {
  const isLastSection = section === referralsStates.educatorLeftHumly

  const { title, info } = getReferralSectionInfo()[section]

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {info ? <Text style={styles.info}>{info}</Text> : null}
      <View style={styles.referralsWrapper}>
        {sectionReferrals.map(referral => (
          <ReferralRow key={referral.id} referral={referral} section={section} />
        ))}
      </View>
      {!isLastSection ? (
        <View style={styles.divider} />
      ) : (
        <ButtonNew
          isShort
          style={styles.button}
          title={translate(translations.loadMore)}
          variant={buttonVariants.outlinedDefault}
        />
      )}
    </View>
  )
}

export default ReferralSection

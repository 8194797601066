import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Image, StyleSheet, Text, TextInput as TextInputBase, TouchableOpacity, View } from 'react-native'

import { ArrowDropDown, ArrowDropUp, CloseIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { InputSelectProps } from './InputSelectProps'
import styles from './styles'
import TextInputNew from 'src/components/Form/TextInputNew'
import images from 'src/global/images'

const InputSelectNew = ({
  disabled,
  filterOnTextChange,
  isAutoCompleteSelect = false,
  label,
  onAutoCompleteClose,
  onBlur,
  onValueChange,
  value,
  values,
  ...props
}: InputSelectProps) => {
  const DropdownButton = useRef<View>(null)
  const [selectedValue, setSelectedValue] = useState(value)
  const [inputValue, setInputValue] = useState(value)
  const [showModal, setShowModal] = useState(false)
  const [dropdownTop, setDropdownTop] = useState(0)
  const [timeout, saveTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const openDropdown = useCallback(() => {
    if (showModal) {
      onAutoCompleteClose && onAutoCompleteClose()
      onBlur && onBlur()
      setInputValue(value)
    }
    if (timeout) clearTimeout(timeout)
    DropdownButton.current?.measure((_fx, _fy, _w, h, _px, _py) => setDropdownTop(_fy + h))
    setShowModal(value => !value)
  }, [onAutoCompleteClose, onBlur, showModal, timeout, value])

  const renderItem = useCallback(
    ({ item }) => (
      <TouchableOpacity
        key={item?.value.toString() + item?.label?.toString()}
        onPress={() => {
          if (item.isReadOnly) return
          onValueChange(item?.value, item?.label)
          setInputValue(item?.value)
          setSelectedValue(item?.value)
          setShowModal(false)
        }}
        style={[
          styles.modalRow,
          !isAutoCompleteSelect && item?.value === selectedValue ? styles.modalRowSelected : null,
        ]}
      >
        <Text numberOfLines={1} style={styles.modalRowLabel}>
          {item?.label}
        </Text>
      </TouchableOpacity>
    ),
    [isAutoCompleteSelect, onValueChange, selectedValue],
  )

  const renderCustomInput = useCallback(
    inputProps => {
      const showClearIcon = inputValue?.length > 0 && isAutoCompleteSelect
      return (
        <TouchableOpacity style={styles.textInputContainer} onPress={() => openDropdown()} disabled={disabled}>
          <View style={styles.input}>
            {isAutoCompleteSelect ? (
              <TextInputBase {...inputProps} value={inputValue ? inputValue : ''} />
            ) : (
              <View {...inputProps}>
                <Text style={styles.textInput}>
                  {values.find(item => item.value === inputProps.value)?.label || ''}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.rightIcon}>
            {showClearIcon && (
              <TouchableOpacity onPress={() => setInputValue('')}>
                <CloseIcon fill={colorsNew.black} size={24} />
              </TouchableOpacity>
            )}
            {showModal ? (
              <ArrowDropUp fill={colorsNew.black} size={24} />
            ) : (
              <ArrowDropDown
                fill={disabled ? colorsNew.redesign.action.disabled : colorsNew.redesign.action.active}
                size={24}
              />
            )}
          </View>
        </TouchableOpacity>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, isAutoCompleteSelect, openDropdown, showModal, disabled],
  )

  useEffect(() => {
    setSelectedValue(value)
    setInputValue(value)
  }, [value])

  return (
    <>
      <View ref={DropdownButton}>
        <TextInputNew
          label={label}
          disabled={disabled}
          onChangeText={text => {
            if (!isAutoCompleteSelect) return
            setInputValue(text)
            if (filterOnTextChange) {
              if (timeout) {
                clearTimeout(timeout)
              }

              const newTimeout = setTimeout(() => {
                filterOnTextChange(text)
                if (newTimeout === timeout) {
                  saveTimeout(null)
                }
              }, 2000)
              saveTimeout(newTimeout)
            }
          }}
          render={renderCustomInput}
          value={selectedValue}
          {...props}
        />
      </View>
      {showModal && (
        <TouchableOpacity
          onPress={() => {
            setShowModal(false)
            onBlur && onBlur()
          }}
          style={StyleSheet.flatten([styles.modalListWrapper, { top: dropdownTop }])}
        >
          <View>{values.map(item => renderItem({ item }))}</View>
          {isAutoCompleteSelect && <Image style={styles.googleLogo} source={images.googleLogo} testID="google-image" />}
        </TouchableOpacity>
      )}
    </>
  )
}

export default InputSelectNew

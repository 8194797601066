import translations, { translate } from 'src/utils/translations/translations'
import { TimeScheduleTypes } from '../graphql/sharableTypes'
import { complianceTypesT } from './types'

export const fullDayOfWeekFormat = `dddd, ${translate(translations.monthAndDayFormat)}`
export const dateFormat = 'YYYY-MM-DD'

export const minSize = global.isIOS ? 44 : 48

export const jobAppointmentType = {
  application: 'application',
  direct: 'direct',
  express: 'express',
}

export const applicationStatus = {
  notApplied: 'notApplied',
  applied: 'applied',
  waitingForResonse: 'waitingForResponse',
  rejected: 'rejected',
  accepted: 'accepted',
  confirmed: 'confirmed',
  cancelled: 'cancelled',
} as const

export const recruitmentSteps = {
  applicationReceived: 'new',
  preinterviewTasks: 'pre_interview',
  interview: 'interview',
  postinterviewTasks: 'post_interview',
  weFindWork: 'accepted',
} as const

export const trainingTypes = { licensedTeacher: 'licensed_teacher', nurseryWorker: 'nursery_worker' }

export const userState = {
  gb: {
    new: 'new',
    preinterviewTasks: 'pre_interview',
    interviewNotBooked: 'interview_not_booked',
    inviteToInterview: 'invite_to_interview',
    interviewBooked: 'interview_booked',
    postinterviewTasks: 'post_interview',
    checkCompliance: 'check_compliance',
    accepted: 'accepted',
    profileDone: 'profile_done',
    oneJob: 'one_job',
    threeJobs: 'three_jobs',
    fiveJobs: 'five_jobs',
    won: 'won',
    rejected: 'rejected',
  },
  se: {
    new: 'new',
    invitedLoops: 'invited_loops',
    interviewBooked: 'interview_booked',
    response: 'response',
    contractSent: 'contract_sent',
    waitingPoliceReport: 'waiting_police_report',
    completeLoops: 'complete_loops',
    accepted: 'accepted',
    profileDone: 'profile_done',
    oneJob: 'one_job',
    threeJobs: 'three_jobs',
    fiveJobs: 'five_jobs',
    won: 'won',
    rejected: 'rejected',
  },
} as const

export const userTitlesSe = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Dr', label: 'Dr' },
]

export const userTitlesGb = [
  ...userTitlesSe,
  { value: 'Professor', label: 'Professor' },
  { value: 'Mx', label: 'Mx' },
  { value: 'Ind', label: 'Ind' },
  { value: 'M', label: 'M' },
  { value: 'Other', label: 'Other' },
]

export const qualificationTypeHash = {
  gcse_type: translations.gsa,
  a_level_type: translations.alevel,
  nvq_type: translations.nvq,
  btec_type: translations.btec,
  undergraduate_degree_type: translations.undergraduate_degree,
  post_graduate_degree_type: translations.post_graduate_degree,
  masters_degree_type: translations.masters_degree,
  phd_type: translations.phd,
  other: translations.other,
}

export const expressStartTime = {
  se: '6:15',
  gb: '7:00',
}

export const competenceScheduleType = {
  fixed: 'full_day/am/pm',
  hourly: 'hourly',
} as const

export const scheduleTypeText = {
  am: 'AM',
  full_day: 'Full day',
  hourly: 'Hourly',
  pm: 'PM',
} as const

export const scheduleTypeTextSv = {
  full_day: 'Hela dagen',
  hourly: 'Valda tider',
}

export const workingTimeList = [
  { value: 'am', label: scheduleTypeText.am },
  { value: 'pm', label: scheduleTypeText.pm },
  { value: 'full_day', label: scheduleTypeText.full_day },
]

type ScheduleTypesListOption = {
  label: string
  value: TimeScheduleTypes
}

type ScheduleTypesList = {
  gb: ScheduleTypesListOption[]
  se: ScheduleTypesListOption[]
}

export const scheduleTypesList = {
  gb: [
    { value: 'full_day', label: scheduleTypeText.full_day },
    { value: 'am', label: scheduleTypeText.am },
    { value: 'pm', label: scheduleTypeText.pm },
    { value: 'hourly', label: scheduleTypeText.hourly },
  ],
  se: [
    { value: 'full_day', label: scheduleTypeTextSv.full_day },
    { value: 'hourly', label: scheduleTypeTextSv.hourly },
  ],
} as ScheduleTypesList

export const compliancesStatus = {
  completed: 'completed',
  expired: 'expired',
  notCompleted: 'notCompleted',
  warning: 'warning',
} as const

export const complianceTypes: complianceTypesT = {
  contractAgreement: 'contractAgreement',
  dbsNumberAppliedOn: 'dbsDeclaration',
  disqualificationDeclarationDeclaredOn: 'disqualificationDeclaration',
  lastHealthCheckConfirmedOn: 'medicalDeclaration',
  policyAgreements: 'policyAgreements',
  safeguardingTrainingOn: 'safeguarding',
}

export const companiesRefs = {
  humlyMidlands: 'humly_midlands',
  humlyMiltonKeynes: 'simply_supply_ltd',
}

export const northpassCourses = {
  safeguardingTraining: 'safeguarding_learning_path_uk',
} as const

export const resetPasswordLink = {
  gb: 'https://account.humly.co.uk/forgot',
  se: 'https://account.humly.io/forgot',
}

export const availabilityStatus = {
  available: 'available',
  booked: 'booked',
  noAdded: 'noAdded',
  notAvailable: 'notAvailable',
}

export const policyURL = { gb: 'https://humly.co.uk/privacy-policy', se: 'https://humly.io/dataskyddspolicy' }
export const ukContactUrl = 'https://humly.co.uk/our-offices/'

export const northpassUrls = {
  bookingConfirmedSEUrl: 'https://humly.northpass.com/c/d9ed62f9045f09c191dd42e7e98e7a6cc5722bb3',
}

export const workPermitInvalidStatusCode = 'work_permit_invalid'

export const GRAPHQL_OPERATION_NAME = {
  logout: 'Logout',
  refreshAccessToken: 'RefreshAccessToken',
}

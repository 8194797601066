import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'

import Checkbox from 'src/components/Form/Checkbox'

import styles from './styles'

const RowWithCheckBox = ({ style, isChecked, disabled, onValueChange, title, testID }) => {
  return (
    <View style={[styles.checkBoxRow, style]}>
      <Checkbox
        disabled={disabled}
        selected={isChecked}
        label={title}
        onPress={() => onValueChange(!isChecked)}
        testID={testID}
        customLabelStyles={styles.label}
      />
    </View>
  )
}

RowWithCheckBox.propTypes = {
  style: PropTypes.any,
  isChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  testID: PropTypes.string,
}

export default RowWithCheckBox

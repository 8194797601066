import React, { useContext } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { InfoOutlinedIcon, PersonRoundedIcon } from 'src/icons'
import { MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ReferralShareButtons } from '../components'
import { useBugsnagView } from 'src/utils/bugsnag'
import FriendOrFoeFullBodyIcon from 'src/icons/redesign/friendOrFoeFullBody'
import RedirectRow from 'src/components/RedirectRow'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

const Referrals = ({ navigation }: MenuNavigatorScreensProps<'Referrals'>) => {
  useBugsnagView('ReferAFriendOrFoe - Referrals')

  const { mixpanel } = useContext(MixpanelContext)

  const goToHowItWorks = () => {
    mixpanel?.track('ReferAFriendOrFoe - How It Works', { action: 'open' })
    navigation.navigate('HowItWorks')
  }
  const goToReferralsList = () => {
    mixpanel?.track('ReferAFriendOrFoe - ReferralsList', { action: 'open' })
    navigation.navigate('ReferralsList')
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={globalStyles.containerSize}
      testID="scrollView"
    >
      <View style={styles.headerWrapper}>
        <FriendOrFoeFullBodyIcon />
        <Text style={styles.info}>{translate(translations.referralsInfo)}</Text>
        <Text style={styles.subInfo}>{translate(translations.referralsSubInfo)}</Text>
      </View>
      <View style={styles.buttonsWrapper}>
        <ReferralShareButtons userHasAcceptedTerms={false} />
      </View>

      <View style={styles.divider} />

      <View style={styles.redirectsRowsWrapper}>
        <RedirectRow
          Icon={PersonRoundedIcon}
          onPress={goToReferralsList}
          title={translate(translations.yourReferrals)}
        />
        <RedirectRow Icon={InfoOutlinedIcon} onPress={goToHowItWorks} title={translate(translations.howItWorks)} />
      </View>
    </ScrollView>
  )
}

export default Referrals

import { LocaleT } from 'src/utils/translations/i18nTypes'
import { ActionTypes } from './actions'
import {
  SET_APP_IS_LOADING,
  SET_BOOKING_UNCONFIRMED,
  SET_CONNECTION_STATUS,
  SET_COUNTRY,
  SET_DEVICE_TOKEN,
  SET_HIDDEN_BOOKING_ALERT_ID,
  SET_SNACKBAR_PROPS,
  SET_TAB_OVERLAY,
} from './types'

export type SnackBarProps = {
  autoHide?: boolean
  content?: string
  duration?: number
  message?: string
  type?: 'success' | 'danger'
  visible?: boolean
}

export type ConnectionTypes = {
  isConnected: boolean
  snackbarProps: SnackBarProps
}

export type AppStateType = {
  countryCode?: LocaleT
  deviceToken: string | null
  hiddenBookingAlertIds: string[]
  isBookingUnconfirmed?: boolean
  isConnected: boolean
  isLoading: boolean
  snackbarProps: SnackBarProps
  tabBarOverlay: boolean
}

export const initialState: AppStateType = {
  countryCode: undefined,
  deviceToken: null,
  hiddenBookingAlertIds: [],
  isBookingUnconfirmed: undefined,
  isConnected: true,
  isLoading: false,
  snackbarProps: {},
  tabBarOverlay: false,
}

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case SET_APP_IS_LOADING: {
      const { payload } = action
      return { ...state, isLoading: payload || payload === undefined }
    }

    case SET_BOOKING_UNCONFIRMED: {
      return { ...state, isBookingUnconfirmed: action.payload }
    }

    case SET_CONNECTION_STATUS: {
      return {
        ...state,
        isConnected: action.payload.isConnected,
        snackbarProps: action.payload.snackbarProps,
      }
    }

    case SET_DEVICE_TOKEN: {
      return { ...state, deviceToken: action.payload }
    }

    case SET_SNACKBAR_PROPS: {
      return { ...state, snackbarProps: action.payload }
    }

    case SET_COUNTRY: {
      return { ...state, countryCode: action.payload }
    }

    case SET_TAB_OVERLAY: {
      return { ...state, tabBarOverlay: action.payload }
    }

    case SET_HIDDEN_BOOKING_ALERT_ID: {
      return {
        ...state,
        hiddenBookingAlertIds: [...(state.hiddenBookingAlertIds || initialState.hiddenBookingAlertIds), action.payload],
      }
    }

    default:
      return state
  }
}

import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'row',
    gap: paddingNew.xs,
    height: 26,
    paddingHorizontal: paddingNew.sm,
    paddingVertical: 3,
  },
  disabledContainer: {
    backgroundColor: colorsNew.transparent,
    borderColor: colorsNew.redesign.action.disabled,
    borderWidth: 1,
    opacity: 0.5,
  },
  disabledText: {
    color: colorsNew.redesign.text.primary,
  },
  give: {
    backgroundColor: colorsNew.redesign.pastel.pink.light,
    hover: { backgroundColor: colorsNew.redesign.pastel.pink.main },
    press: { backgroundColor: colorsNew.redesign.pastel.pink.dark },
  },
  split: {
    backgroundColor: colorsNew.redesign.pastel.blue.light,
    hover: { backgroundColor: colorsNew.redesign.pastel.blue.main },
    press: { backgroundColor: colorsNew.redesign.pastel.blue.dark },
  },
  take: {
    backgroundColor: colorsNew.redesign.pastel.purple.light,
    hover: { backgroundColor: colorsNew.redesign.pastel.purple.main },
    press: { backgroundColor: colorsNew.redesign.pastel.purple.dark },
  },
  text: {
    color: colorsNew.redesign.primary.contrast,
    ...typography.chipTextSmall,
  },
})

export default styles

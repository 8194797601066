import React, { useEffect } from 'react'
import { Linking } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import SplashScreen from 'src/utils/webAdapters/SplashScreenAdapter'
import { enableScreens } from 'react-native-screens'
import { throttle } from 'lodash'

import Loading from 'src/utils/Loading'
import LoginRoutes from './scenes/Welcome/LoginRoutes'
import PrivateRoutes from './PrivateRoutes'
import { bugsnagNotifyWithData, bugsnagViewBreadcrumb } from 'src/utils/bugsnag'
import { MainParamList } from './utils/types/navigationTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { savedDeepLink } from 'src/savedDeepLink'

const Stack = createNativeStackNavigator<MainParamList>()

const options = {
  header: () => null,
  gesturesEnabled: false,
}

enableScreens()

const Routes = () => {
  const isLoading = useAppSelector(state => state.app.isLoading)
  const userId = useAppSelector(state => state.user.id)
  const userPersist = useAppSelector(state => state.user?._persist?.rehydrated) || true

  const appIsLoading = isLoading || !userPersist
  const appIsLoaded = !isLoading && userPersist

  useEffect(() => {
    if (appIsLoaded) {
      bugsnagViewBreadcrumb('appIsLoaded in Routes')

      setTimeout(SplashScreen.hide, 500)
    }
  }, [appIsLoaded])

  const _handleOpenURL = (url: string) => {
    if (url && appIsLoaded && !userId) {
      savedDeepLink.url = url
    }
  }

  const throttleOpenUrl = throttle(_handleOpenURL, 2000)

  useEffect(() => {
    Linking.getInitialURL()
      .then((link: string | null) => {
        if (link) {
          throttleOpenUrl(link)
        }
      })
      .catch(err => {
        bugsnagNotifyWithData('DeepLink Error', err)
      })

    const listener = Linking.addEventListener('url', event => throttleOpenUrl(event?.url))

    return () => {
      listener.remove()
    }
  }, [])

  return (
    <Stack.Navigator>
      {appIsLoading && <Stack.Screen component={Loading} name="Loading" options={options} />}
      {appIsLoaded && !userId && <Stack.Screen component={LoginRoutes} name="LoginRoutes" options={options} />}
      {appIsLoaded && userId && <Stack.Screen component={PrivateRoutes} name="PrivateRoutes" options={options} />}
    </Stack.Navigator>
  )
}

export default Routes

import { View, Text, Image, ActivityIndicator, ScrollView } from 'react-native'
import React, { useState } from 'react'

import { AppScreenProps } from 'src/utils/types/navigationTypes'
import { colorsNew } from 'src/styles'
import { getSchoolPlaceholder } from './schoolPlaceholders'
import BadgeItem from 'src/components/BadgeItem'
import DownloadDocument from 'src/components/DownloadDocument'
import ExpandableSection from './ExpandableSection/ExpandableSection'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

const ClientDetails = ({ route }: AppScreenProps<'ClientDetails'>) => {
  const { school, level } = route?.params || {}
  const { description, files, name, processArrival, processHandover, processOther, profilePictureUrl } = school

  const [showPictureLoader, setShowPictureLoader] = useState(!!profilePictureUrl)

  const schoolImageSource = profilePictureUrl ? { uri: profilePictureUrl } : getSchoolPlaceholder(level.group)

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.pageContent, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <Image onLoad={() => setShowPictureLoader(false)} source={schoolImageSource} style={styles.backgroundImage} />
      {showPictureLoader ? (
        <View style={[styles.backgroundImage, styles.loader]}>
          <ActivityIndicator color={colorsNew.redesign.primary.main} size="small" />
        </View>
      ) : null}

      <Text style={styles.schoolName}>{name}</Text>

      {level?.title ? (
        <View style={styles.badgeContainer}>
          <BadgeItem title={level.title} type="standard" testID="level" key={level.id} />
        </View>
      ) : null}

      <ExpandableSection content={description} title={translate(translations.clientInformation)} />
      <View style={styles.divider} />

      <ExpandableSection content={processArrival} title={translate(translations.clientProceduresArriving)} />
      <ExpandableSection content={processHandover} title={translate(translations.clientProceduresLeaving)} />
      <ExpandableSection content={processOther} title={translate(translations.clientRoutines)} />

      {files?.length > 0 ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{translate(translations.clientDocuments)}</Text>
          {files.map(file => (
            <DownloadDocument url={file.url} name={file.name} key={file.id} />
          ))}
        </View>
      ) : null}
    </ScrollView>
  )
}

export default ClientDetails

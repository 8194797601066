import React, { useContext, useState } from 'react'
import { View } from 'react-native'

import { acceptOnCall, ignoreOnCall } from '../actions'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { MixpanelContext } from 'src/context/MixpanelContext'
import ActionsOverlay from 'src/scenes/Main/Jobs/Details/components/ActionsOverlay'
import ApplicationModal, { ApplicationOfferDataType } from 'src/scenes/Main/Jobs/Details/components/ApplicationModal'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ErrorModal from 'src/scenes/Main/Jobs/Details/components/ErrorModal'
import IgnoreOfferModal from 'src/scenes/Main/Jobs/Details/components/IgnoreOfferModal'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import WorkPermitWarningModal from 'src/scenes/Main/Jobs/Details/components/WorkPermitWarningModal/WorkPermitWarningModal'

type Props = {
  expressPoolId: string
  expressDate: string
  goBack: () => void
  isUpdating: boolean
  isUserWorkPermitInvalid: boolean
  jobConfirmationData: ApplicationOfferDataType
  setIsUpdating: (value: boolean) => void
  setIsApplicationCompleted: (value: boolean) => void
  workPermitExpiresOn: string
}

const AcceptOverlay = ({
  expressDate,
  expressPoolId,
  goBack,
  isUpdating,
  isUserWorkPermitInvalid,
  jobConfirmationData,
  setIsApplicationCompleted,
  setIsUpdating,
  workPermitExpiresOn,
}: Props) => {
  const { isUserCompliant } = useContext(ComplianceStatusContext)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [showWorkPermitModal, setShowWorkPermitModal] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const onRequestError = (errorDetails: string, isWorkPermitInvalid?: boolean) => {
    if (isWorkPermitInvalid) {
      setShowWorkPermitModal(true)
    } else {
      setErrorMessage(errorDetails)
    }

    setIsUpdating(false)
  }

  const onConfirm = () => {
    setShowConfirmationModal(false)
    setIsUpdating(true)
    bugsnagActionBreadcrumb('acceptExpressBooking')
    mixpanel?.track('Apply Express', { expressId: expressPoolId })

    acceptOnCall(
      {
        date: expressDate,
        expressBookingPool: expressPoolId,
      },
      () => {
        setIsUpdating(false)
        setIsApplicationCompleted(true)
      },
      onRequestError,
    )
  }

  const onIgnore = () => {
    setShowRejectionModal(false)
    setIsUpdating(true)
    bugsnagActionBreadcrumb('ignoreExpessBooking')
    mixpanel?.track('Reject Express', { expressId: expressPoolId })

    ignoreOnCall(
      { date: expressDate },
      () => {
        goBack()
        setIsUpdating(false)
      },
      onRequestError,
    )
  }

  return (
    <>
      <ActionsOverlay>
        <ButtonNew
          disabled={isUpdating || !isUserCompliant}
          isLong
          onPress={() => setShowRejectionModal(true)}
          testID="ignore-button"
          title={translate(translations.jobOfferIgnore)}
          variant={buttonVariants.outlinedDefault}
        />
        <View style={styles.divider} />

        <ButtonNew
          disabled={isUpdating || !isUserCompliant || isUserWorkPermitInvalid}
          flexBasis="60%"
          onPress={() => setShowConfirmationModal(true)}
          RightIcon={ChevronRightIcon}
          testID="accept-button"
          title={translate(translations.applyForJob)}
          variant={buttonVariants.containedDefault}
        />
      </ActionsOverlay>

      {!errorMessage ? (
        <ApplicationModal
          closeApplicationModal={() => setShowConfirmationModal(false)}
          isVisible={showConfirmationModal}
          onConfirm={onConfirm}
          {...jobConfirmationData}
        />
      ) : (
        <ErrorModal
          closeErrorModal={() => setErrorMessage(null)}
          description={errorMessage}
          isVisible={!!errorMessage}
        />
      )}

      <IgnoreOfferModal
        closeIgnoreModal={() => setShowRejectionModal(false)}
        isVisible={showRejectionModal}
        onIgnore={onIgnore}
      />

      <WorkPermitWarningModal
        closeWarningModal={() => setShowWorkPermitModal(false)}
        isVisible={showWorkPermitModal}
        workPermitExpiresOn={workPermitExpiresOn}
      />
    </>
  )
}

export default AcceptOverlay

import React, { useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { Error, Label } from 'src/components/Form/Common'

import styles from './styles'

const InputText = props => {
  const {
    autoCapitalize,
    blurOnSubmit,
    containerStyles,
    editable = true,
    errorInputStyle,
    errorMessageStyle,
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    inputStyle,
    keyboardType,
    label,
    labelStyles,
    multiline = false,
    onChangeEffect,
    onSubmitEditing,
    placeholder,
    placeholderTextColor,
    required,
    returnKeyType,
    secureTextEntry,
  } = props
  const [active, setActive] = useState(false)
  const isTouched = get(touched, name)
  const hasError = isTouched && get(errors, name)

  return (
    <View style={StyleSheet.flatten([styles.container, containerStyles, multiline && styles.multilineContainer])}>
      <Label labelStyles={StyleSheet.flatten([styles.label, labelStyles])} label={label} required={required} />
      <TextInput
        autoCapitalize={autoCapitalize}
        blurOnSubmit={blurOnSubmit}
        editable={editable}
        keyboardType={keyboardType}
        multiline={multiline}
        onSubmitEditing={onSubmitEditing}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        onBlur={() => {
          setFieldTouched(name)
          setActive(false)
          onBlur(name)
        }}
        onChangeText={text => {
          onChange(name)(text)
          onChangeEffect && onChangeEffect(text)
        }}
        onFocus={() => {
          setActive(true)
        }}
        ref={props.forwardRef}
        returnKeyType={returnKeyType}
        secureTextEntry={secureTextEntry}
        style={StyleSheet.flatten([
          styles.textInput,
          active && styles.touchInput,
          hasError && (errorInputStyle || styles.errorInput),
          multiline && styles.multiline,
          inputStyle,
        ])}
        testID={name}
        value={value}
      />
      <Error errorMessageStyle={errorMessageStyle} errors={isTouched ? errors : {}} name={name} />
    </View>
  )
}

export default InputText

InputText.propTypes = {
  autoCapitalize: PropTypes.string,
  containerStyles: PropTypes.shape({}),
  errorInputStyle: PropTypes.shape({}),
  errorMessageStyle: PropTypes.shape({}),
  field: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
  }).isRequired,
  label: PropTypes.string,
  onChangeEffect: PropTypes.func,
  placeholderTextColor: PropTypes.string,
  required: PropTypes.bool,
}

import React from 'react'
import { View, Platform, Text, ScrollView } from 'react-native'

import styles from './styles'

const WebWrapper = ({ children, shadowOffsetTop = 0 }) => {
  if (Platform.OS === 'web') {
    return (
      <View style={styles.wrapperContainer}>
        <ScrollView contentContainerStyle={styles.container}>
          <View
            style={[
              styles.wrapper,
              {
                boxShadow: `0px ${shadowOffsetTop}px 21px -9px rgba(66, 68, 90, 1)`,
              },
            ]}
            testID="web-style-wrapper"
          >
            {children}
            <div id="modal" />
          </View>
        </ScrollView>
      </View>
    )
  }

  return children
}

export default WebWrapper

import { Formik } from 'formik'
import { ScrollView, View } from 'react-native'
import { useLoadScript } from '@react-google-maps/api'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect, useMemo } from 'react'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

import { addressValidationSchema } from './addressValidationSchema'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { buttonVariants, buttonSize } from 'src/components/Buttons/ButtonNew/variants'
import { colors, common } from 'src/styles'
import { getCountryCode } from 'src/store/app/selectors'
import { getLanguageByLocation } from 'src/utils/translations/i18n'
import { InputSelectNew } from 'src/components/Form'
import { InputSelectSizeVariants } from 'src/components/Form/InputSelectNew'
import { MenuParamList, RouteNavigatorStackComponent } from 'src/utils/types/navigationTypes'
import { updateAddresses } from 'src/store/addresses/actions'
import { googleMapsApiKey, handleDetailsResponse, mapAutocompleteResponse } from '../helpers/autocomplete'
import ButtonNew from 'src/components/Buttons/ButtonNew'
import LoadingIndicator from 'src/components/LoadingIndicator'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import globalStyles from 'src/global/globalStyles'

interface InitialValuesTypes {
  city: string
  id: string
  street: string
  zip: string
}

const AddressForm: RouteNavigatorStackComponent<MenuParamList, 'AddressForm'> = ({ navigation: { goBack }, route }) => {
  const [loading, setLoading] = useState(false)
  const [placesIds, setPlacesIds] = useState([{ label: '', placeId: '' }])

  const countryCode = useSelector(getCountryCode) as LocaleT

  const language = getLanguageByLocation(countryCode)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ['places'],
  })

  const initialValues: InitialValuesTypes = useMemo(
    () => (route.params.address ? route.params.address : { city: '', id: '', street: '', zip: '' }),
    [route.params.address],
  )

  const {
    init: initStreetAutocomplete,
    suggestions: { data: streetValues, status: streetAutocompleteStatus },
    setValue: setStreetValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['street_address', 'premise'],
      language,
    },
    initOnMount: false,
  })

  const {
    init: initZipAutocomplete,
    suggestions: { data: zipValues, status: zipAutocompleteStatus },
    setValue: setZipValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['postal_code'],
      language,
    },
    initOnMount: false,
  })

  const {
    init: initCityAutocomplete,
    suggestions: { data: cityValues, status: cityAutocompleteStatus },
    setValue: setCityValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['postal_code'],
      language,
    },
    initOnMount: false,
  })

  useBugsnagView('AddressForm')

  useEffect(() => {
    if (isLoaded) {
      initStreetAutocomplete()
      setStreetValue(initialValues.street)
      initZipAutocomplete()
      setZipValue(initialValues.zip)
      initCityAutocomplete()
      setCityValue(initialValues.city)
    }
  }, [
    isLoaded,
    initStreetAutocomplete,
    setStreetValue,
    initialValues,
    initZipAutocomplete,
    setZipValue,
    initCityAutocomplete,
    setCityValue,
  ])

  useEffect(() => {
    setPlacesIds(
      streetValues.map(({ place_id, description }) => ({
        label: description,
        placeId: place_id,
      })),
    )
  }, [streetValues])

  const onSubmit = async (values: InitialValuesTypes) => {
    bugsnagActionBreadcrumb('AddressForm submit', { values })

    const updatedOtherAddresses =
      route.params.user.otherAddresses.map(address =>
        address.id === route.params.address.id
          ? { ...address, city: values.city, street: values.street, zip: values.zip }
          : address,
      ) || []

    setLoading(true)
    await updateAddresses({ otherAddresses: updatedOtherAddresses }, route.params.user.id, goBack)
    setLoading(false)
  }

  const autocompleteInputs = async (
    response: string | google.maps.places.PlaceResult,
    setFieldValue: (key: string, value: boolean) => void,
  ) => {
    const { zip, city } = await handleDetailsResponse(response)
    setFieldValue('zip', zip)
    setFieldValue('city', city)
  }

  const requestMoreDetails = (value: string, label: string, setFieldValue: (key: string, value: any) => void) => {
    setFieldValue('street', value)
    const placeId = placesIds.find(x => x.label === label)?.placeId
    if (!placeId) return

    const parameter = {
      placeId: placeId,
      language,
    }

    getDetails(parameter)
      .then(details => {
        autocompleteInputs(details, setFieldValue)
      })
      .catch(() => {
        return
      })
  }

  if (!isLoaded) {
    return <LoadingIndicator style={{ backgroundColor: colors.white }} />
  }

  return (
    <ScrollView style={common.whiteBackground} contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      <View style={styles.container}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={addressValidationSchema}>
          {({ errors, handleSubmit, isValid, setFieldValue, values }) => (
            <>
              <View style={styles.inputRow}>
                <InputSelectNew
                  disabled={false}
                  errorMessage={errors.street}
                  filterOnTextChange={text => setStreetValue(text)}
                  isAutoCompleteSelect={true}
                  isError={!isEmpty(errors.street)}
                  label={translate(translations.street)}
                  onAutoCompleteClose={() => setStreetValue(values.street)}
                  onValueChange={(value: string, label: string) => requestMoreDetails(value, label, setFieldValue)}
                  required
                  size={InputSelectSizeVariants.medium}
                  value={values.street}
                  values={mapAutocompleteResponse(streetValues, streetAutocompleteStatus)}
                />
              </View>
              <View style={styles.inputRow}>
                <InputSelectNew
                  disabled={false}
                  errorMessage={errors.zip}
                  filterOnTextChange={text => setZipValue(text)}
                  isAutoCompleteSelect={true}
                  isError={!isEmpty(errors.zip)}
                  label={translate(translations.zip)}
                  onAutoCompleteClose={() => {
                    setZipValue(values.zip)
                  }}
                  onValueChange={(value: string) => {
                    setFieldValue('zip', value)
                  }}
                  required
                  size={InputSelectSizeVariants.medium}
                  value={values.zip}
                  values={mapAutocompleteResponse(zipValues, zipAutocompleteStatus)}
                />
              </View>
              <View style={styles.inputRow}>
                <InputSelectNew
                  disabled={false}
                  errorMessage={errors.city}
                  filterOnTextChange={text => setCityValue(text)}
                  isAutoCompleteSelect={true}
                  isError={!isEmpty(errors.city)}
                  label={translate(translations.city)}
                  onAutoCompleteClose={() => {
                    setCityValue(values.city)
                  }}
                  onValueChange={(value: string) => {
                    setFieldValue('city', value)
                  }}
                  required
                  size={InputSelectSizeVariants.medium}
                  value={values.city}
                  values={mapAutocompleteResponse(cityValues, cityAutocompleteStatus)}
                />
              </View>
              <View style={styles.buttonContainer}>
                <ButtonNew
                  disabled={!isValid}
                  isSubmitting={loading}
                  onPress={handleSubmit}
                  size={buttonSize.lg}
                  title={loading ? '' : translate(translations.saveChanges)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </>
          )}
        </Formik>
      </View>
    </ScrollView>
  )
}

export default AddressForm

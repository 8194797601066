import React, { useContext, useRef, useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { ScrollView, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { HomeAddressTypes } from 'src/graphql/HomeAddress'
import { InputDate, InputSelectNew } from 'src/components/Form'
import { isGBSelector } from 'src/store/app/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { TitleT } from 'src/store/user/userTypes'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { userTitlesGb } from 'src/global/constants'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import getValidationSchema from './validationSchema'
import nationalities from 'src/utils/nationalities'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'PersonalDetails'>
type InitialValuesType = {
  birthDate: string
  email: string
  firstName: string
  homeAddress: {
    addressLine2: string
    city: string
    county: string
    street: string
    zip: string
  }
  landPhone: string
  lastName: string
  nationality: string
  phone: string
  salutation: string
  title: TitleT | ''
}

const PersonalDetails = ({ navigation, route }: Props) => {
  const formikRef = useRef<FormikProps<InitialValuesType>>(null)
  const dispatch = useAppDispatch()
  const userAddress = useAppSelector(state => state.user.attributes?.address) as HomeAddressTypes | undefined
  const userBirthDate = useAppSelector(state => state.user.attributes?.birthDate)
  const userEmail = useAppSelector(state => state.user.attributes?.email)
  const userFirstName = useAppSelector(state => state.user.attributes?.firstName)
  const userId = useAppSelector(state => state.user?.id) as string
  const userLandPhone = useAppSelector(state => state.user.attributes?.landPhone)
  const userLastName = useAppSelector(state => state.user.attributes?.lastName)
  const userNationality = useAppSelector(state => state.user.attributes?.nationality)
  const userPhone = useAppSelector(state => state.user.attributes?.phone)
  const userSalutation = useAppSelector(state => state.user.attributes?.salutation)
  const userTitle = useAppSelector(state => state.user.attributes?.title)
  const isGB = useAppSelector(isGBSelector)

  const { mixpanel } = useContext(MixpanelContext)

  const [isError, setIsError] = useState(false)

  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - PersonalDetails')

  const initialValues: InitialValuesType = {
    birthDate: userBirthDate || '',
    email: userEmail || '',
    firstName: userFirstName || '',
    homeAddress: {
      addressLine2: userAddress?.addressLine2 || '',
      city: userAddress?.city || '',
      county: userAddress?.county || '',
      street: userAddress?.street || '',
      zip: userAddress?.zip || '',
    },
    landPhone: userLandPhone || '',
    lastName: userLastName || '',
    nationality: userNationality || '',
    phone: userPhone || '',
    salutation: userSalutation || '',
    title: userTitle || '',
  }

  const closeScreen = useBackModal(navigation, formikRef)
  const handleError = () => setIsError(true)
  const updateTaskStatus = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const submitForm = (values: InitialValuesType, form: FormikHelpers<InitialValuesType>) => {
    bugsnagActionBreadcrumb('submit', { id: userId, user: values })

    setIsError(false)
    dispatch(
      saveUser({
        onError: () => {
          handleError()
          form.setSubmitting(false)
        },
        onSuccess: isCompleted ? closeScreen : updateTaskStatus,
        id: userId,
        user: values,
      }),
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.listContainer, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo status={status} timeToComplete={timeToComplete} />
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={submitForm}
        validateOnMount
        validationSchema={getValidationSchema(isGB)}
      >
        {({ dirty, errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => (
          <>
            {isGB ? (
              <InputSelectNew
                disabled={isCompleted}
                errorMessage={errors.title}
                isError={!isEmpty(errors.title)}
                label={translate(translations.title)}
                onBlur={() => setFieldTouched('title', true)}
                onValueChange={value => setFieldValue('title', value)}
                testID="title-input"
                value={values.title}
                values={userTitlesGb}
              />
            ) : null}
            <TextInputNew
              disabled={isCompleted}
              errorMessage={errors.firstName}
              isError={!isEmpty(errors.firstName)}
              label={translate(translations.firstName)}
              onBlur={() => setFieldTouched('firstName', true)}
              onChangeText={text => setFieldValue('firstName', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="firstName-input"
              value={values.firstName}
            />
            <TextInputNew
              disabled={isCompleted}
              errorMessage={errors.lastName}
              isError={!isEmpty(errors.lastName)}
              label={translate(translations.lastName)}
              onBlur={() => setFieldTouched('lastName', true)}
              onChangeText={text => setFieldValue('lastName', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="lastName-input"
              value={values.lastName}
            />
            {isGB ? (
              <>
                <TextInputNew
                  disabled={isCompleted}
                  errorMessage={errors.salutation}
                  helperText={translate(translations.enterSalutation)}
                  isError={!isEmpty(errors.salutation)}
                  label={translate(translations.salutation)}
                  onBlur={() => setFieldTouched('salutation', true)}
                  onChangeText={text => setFieldValue('salutation', text)}
                  size={TextInputSizeVariants.medium}
                  testID="salutation-input"
                  value={values.salutation}
                />
                <View style={styles.divider} />
              </>
            ) : null}
            <InputDate
              disabled={isCompleted}
              field={{ name: 'birthDate', value: values.birthDate }}
              form={{ errors, setFieldTouched, setFieldValue, touched }}
              label={translate(translations.birthDate)}
              required
              testID="birthDate-input"
              useNew
            />
            {isGB ? (
              <InputSelectNew
                disabled={isCompleted}
                errorMessage={errors.nationality}
                isError={!isEmpty(errors.nationality)}
                label={translate(translations.nationality)}
                onBlur={() => setFieldTouched('nationality', true)}
                onValueChange={value => setFieldValue('nationality', value)}
                required
                testID="nationality-input"
                value={values.nationality}
                values={nationalities}
              />
            ) : null}
            <TextInputNew
              errorMessage={errors.email}
              isError={!isEmpty(errors.email)}
              keyboardType="email-address"
              label={translate(translations.email)}
              onBlur={() => setFieldTouched('email', true)}
              onChangeText={text => setFieldValue('email', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="email-input"
              title={translate(translations.contactDetails)}
              value={values.email}
            />
            <TextInputNew
              errorMessage={errors.phone}
              isError={!isEmpty(errors.phone)}
              keyboardType="numeric"
              label={translate(translations.phoneNumber)}
              onBlur={() => setFieldTouched('phone', true)}
              onChangeText={text => setFieldValue('phone', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="phone-input"
              value={values.phone}
            />
            {isGB ? (
              <TextInputNew
                errorMessage={errors.landPhone}
                isError={!isEmpty(errors.landPhone)}
                keyboardType="numeric"
                label={translate(translations.landPhone)}
                onBlur={() => setFieldTouched('landPhone', true)}
                onChangeText={text => setFieldValue('landPhone', text)}
                size={TextInputSizeVariants.medium}
                testID="landPhone-input"
                value={values.landPhone}
              />
            ) : null}
            <TextInputNew
              disabled={isGB && isCompleted}
              errorMessage={errors.homeAddress?.street}
              isError={!isEmpty(errors.homeAddress?.street)}
              label={translate(translations.houseNumberAndStreet)}
              onBlur={() => setFieldTouched('homeAddress.street', true)}
              onChangeText={text => setFieldValue('homeAddress.street', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="street-input"
              title={translate(translations.address)}
              value={values.homeAddress.street}
            />
            {isGB ? (
              <>
                <TextInputNew
                  disabled={isCompleted}
                  errorMessage={errors.homeAddress?.addressLine2}
                  isError={!isEmpty(errors.homeAddress?.addressLine2)}
                  label={translate(translations.addressLine2)}
                  onBlur={() => setFieldTouched('homeAddress.addressLine2', true)}
                  onChangeText={text => setFieldValue('homeAddress.addressLine2', text)}
                  size={TextInputSizeVariants.medium}
                  value={values.homeAddress.addressLine2}
                />
                <TextInputNew
                  disabled={isCompleted}
                  errorMessage={errors.homeAddress?.city}
                  isError={!isEmpty(errors.homeAddress?.city)}
                  label={translate(translations.town)}
                  onBlur={() => setFieldTouched('homeAddress.city', true)}
                  onChangeText={text => setFieldValue('homeAddress.city', text)}
                  required
                  size={TextInputSizeVariants.medium}
                  testID="city-input"
                  value={values.homeAddress.city}
                />
              </>
            ) : null}
            <TextInputNew
              disabled={isGB && isCompleted}
              errorMessage={errors.homeAddress?.zip}
              isError={!isEmpty(errors.homeAddress?.zip)}
              label={translate(translations.zip)}
              onBlur={() => setFieldTouched('homeAddress.zip', true)}
              onChangeText={text => setFieldValue('homeAddress.zip', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="zip-input"
              value={values.homeAddress.zip}
            />
            {!isGB ? (
              <TextInputNew
                errorMessage={errors.homeAddress?.city}
                isError={!isEmpty(errors.homeAddress?.city)}
                label={translate(translations.city)}
                onBlur={() => setFieldTouched('homeAddress.city', true)}
                onChangeText={text => setFieldValue('homeAddress.city', text)}
                required
                size={TextInputSizeVariants.medium}
                testID="city-input"
                value={values.homeAddress.city}
              />
            ) : (
              <TextInputNew
                disabled={isCompleted}
                errorMessage={errors.homeAddress?.county}
                isError={!isEmpty(errors.homeAddress?.county)}
                label={translate(translations.county)}
                onBlur={() => setFieldTouched('homeAddress.county', true)}
                onChangeText={text => setFieldValue('homeAddress.county', text)}
                size={TextInputSizeVariants.medium}
                value={values.homeAddress.county}
              />
            )}
            {isError ? <CompleteTaskError hideError={() => setIsError(false)} /> : null}
            <View style={styles.buttonContainer}>
              <ButtonNew
                disabled={(isCompleted && !dirty) || !isValid}
                isSubmitting={isSubmitting}
                onPress={handleSubmit}
                testID="submit-personal-details"
                title={translate(translations.saveChanges)}
                variant={buttonVariants.containedDefault}
              />
            </View>
          </>
        )}
      </Formik>
    </ScrollView>
  )
}

export default PersonalDetails

import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

import styles from './styles'

type Props = {
  label?: string
  labelStyles?: object
  required?: boolean
}

const Label = ({ label, labelStyles, required }: Props) => (
  <View style={styles.labelContainer}>
    {label ? (
      <Text style={StyleSheet.flatten([styles.labelText, labelStyles])}>
        {label} {required ? <Text style={styles.required}>*</Text> : null}
      </Text>
    ) : null}
  </View>
)

export default Label

import { onboardingEn } from './onboardingEn'

export default {
  aboutMe: 'Description of yourself',
  aboutMeDescription:
    'Write a short description about yourself which schools can see. You could write a bit about your background or explain why you like teaching and working with children and young people.',
  aboutMePlaceholder: 'Describe yourself here.',
  academy: 'Academy',
  accept: 'Accept',
  acceptAgreements: 'I confirm the policy',
  acceptedInfo:
    'We will now review you and the other interested candidates and send your information to the school. We’ll let you know by telephone or email once the school has made a decision. Feel free to get in touch if you have any questions!',
  acceptedInfoDescription:
    'Congratulations! The schools has decided to appoint you to this job. Please confirm that you have read this by clicking the button above.',
  acceptedInfoTitle: 'The job is yours!',
  acceptOnCallDescription:
    'Are you sure you want to be an on-call supply teacher on this day?\n\nKeep an eye on your phone from 06.15. You’ll get a notification as soon as we have found a job for you.',
  addArea: 'Add new',
  addAvailability: 'Add availability to get notifications',
  addAvailabilityWeek: 'Make me available all week',
  addComment: 'Please enter a comment.',
  addNewAddress: 'Add new address',
  address: 'Address',
  addressEx: 'Address Line 1',
  addressLine2: 'Address Line 2',
  addSchool: 'Add new',
  adjustMyPreferences: 'Adjust My Preferences',
  agreementLinkTitle: 'Review the data policy',
  agreements: 'Agreements',
  agreementsInfoTitle: 'Data policy',
  agreementTermsAndConditions: 'I agree to the complete terms and conditions.',
  agreementText:
    'We take data protection very seriously and comply with all current legislation. For full details of how we process your data please refer to our Privacy Notice and Data Protection Policy which can be found on our website.',
  alertUserRequestedTitle: 'Hurry, you’ve been requested! 💚🦸',
  alertUserRequestedDescription:
    'You must’ve made a brilliant impression last time because %{school} wants you back! But please accept the job as soon as possible, if you want it, because other brilliant educators may have been requested too.',
  alertFlexibleOfferTitle: 'This job is starting soon! ⏱',
  alertFlexibleOfferDescription:
    'So, if you want it, %{school} asks that you arrive as close to %{startTime} as possible. And please remember to update your correct start time in the app after the work day ends.',
  alertUnconfirmedTitle: 'We want you for this job, confirm now!',
  alertUnconfirmedDescription:
    'We think you will be a great fit for this role. Please reach out if you have any questions. Good luck!',
  alertOfferCanceledTitle: 'This job got cancelled! 🥀 ',
  alertOfferCanceledDescription:
    'Unfortunately, %{school} has cancelled the job and no longer has a need. Please take a look if there is another job that suits you, or contact us and we will help you.',
  alertOfferNotAvailableTitle: 'This job is no longer available',
  alertOfferNotAvailableDescription:
    'Sorry, but you didn’t get this job this time. Thankfully, we’ve got more great jobs for you to check out coming in every day. Are any of them perfect for you? 👀',
  alertUserNotCompliant:
    'Oh no! It looks like your compliance is out of date. But don’t worry, you just need to do update your status to apply for and book the jobs you want.',
  alertWorkPermitInvalid: `Unfortunately, your work permit is only valid until %{workPermitExpiresOn}, which doesn't cover all dates within this booking. For guidance about updating or renewing your work permit, contact the office.`,
  alertWorkPermitInvalidTitle: 'Can’t accept your application',
  alertUserAppliedTitle: 'Thanks for applying! ️⏳',
  alertUserAppliedDescription:
    'Unfortunately, we cannot give you an answer directly on this job. You can still apply for other jobs on the same day that suits you - as this will help you get booked. We make sure you are not double booked.',
  alertPendingTitle: 'You got the job, please confirm now! 🚀',
  alertConfirmedTitle: 'You got the job! 🎉',
  alertConfirmedDescription:
    'You were perfect for this role. Congrats! Please reach out if you risk being late or have any questions. And good luck!',
  allAmountsShown: 'All amounts shown are',
  allInformation: 'Here’s a summary of important information relating to the job.',
  allYourCompletedJobs: 'All Your Completed Jobs',
  almostThere: 'The job is nearly yours!',
  alreadyAppointed: 'Job already filled',
  alreadyAppointedToYou: 'Booking has already been appointed to you by support or yourself.',
  AM: 'AM',
  androidRateUs: 'Give us five stars!',
  answerQuestions: 'Answer all questions',
  anyQuestionLabel: 'Do you have any question?',
  anyQuestionsAboutTheTermsContactUs:
    'Do you have questions about the terms and conditions? Give us a call, or send us an email!',
  applicationDescription:
    'Can you be a good match for this job? Let them know that you are interested! The school will reply through the app before',
  applicationFormHeader: 'Glad you are interested in the job!  🎉',
  applicationFormDescription: 'Please tell us why you’re the right fit for this role?',
  applicationFormPlaceholder: 'Please describe',
  applicationFormSubmit: 'Apply',
  applicationFormSubmitted: 'Application Sent!',
  applicationInfo1:
    'Submitting interest is binding so it is important that you only submit interest for jobs when you are certain you are available to work. You can submit interest in multiple jobs on the same day, if you are appointed the conflicting interests will automatically be withdrawn. You can withdraw your interest until the school has made their decision.',
  applicationInfo2:
    'We strive to give you notice as soon as possible. The time limit varies and can be found in the booking info, but it’s never longer than 24h. You will get the reply from the school here in the app.',
  applicationInfo3: 'If you are chosen by the school, you must confirm the appointment through the app.',
  applicationInfo: 'This is the process when submitting interest for a job in the Humly app.',
  applicationInfoButton: 'How does it work?',
  applicationInfoConfirmButton: 'I understand!',
  applicationInfoInterestModalHeader: 'Submit Interest',
  applicationInfoModalHeader: 'Submit interest',
  applicationInfoTitle1: '1. Submitting interest',
  applicationInfoTitle2: '2. Notice within 24h',
  applicationInfoTitle3: '3. You confirm the final appointment',
  applicationInfoTitle: 'Submitting interest is binding',
  applicationModalDescription:
    'You will get the reply from the school through this app. You can submit interest in multiple jobs - the Humly app will keep track of everything and make sure you only get appointed to one.',
  applicationModalHeader: 'Job application',
  applicationModalInfo: 'You are about to submit interest for a job these dates: ',
  applicationModalTerms1: 'I can work all dates listed above.',
  applicationModalTerms2: 'I understand that I ',
  applicationModalTerms3: ' work these days if the school chooses to appoint me.',
  applicationModalTerms4:
    'I understand that I will have a reply from the school at the latest %{limit}, but it might be quicker.',
  applicationModalTermsTitle: 'Read through and confirm these terms for your interest submission.',
  applications: 'Applications',
  appliedHeader: 'THANK YOU FOR YOUR INTEREST!',
  appliedTitle: 'This happens next',
  apply: 'Apply',
  applyForJob: 'I’m interested',
  applyFormUrl: 'https://apply.humly.co.uk',
  applyForProposal: 'I’m interested!',
  applyHeader: 'Show your interest in the job',
  applyIcon: 'Apply',
  applyNow: 'Apply now!',
  applySubstituteTeacher: 'Apply now',
  appointDirectly: 'Appoint directly',
  appointed: 'Booked',
  appointedIcon: 'Appointed',
  appointedJobMessage1: 'Hey there! It’s great that you are interested in this job. The job at ',
  appointedJobMessage2:
    'has unfortunately been taken by another supply teacher already. But maybe there’s another job that’s right for you?',
  appointRequest: 'Requested You - Appoint Directly',
  approximateDates: 'Approximate dates',
  area: 'Area',
  areaPreferences: 'Area Preferences',
  areYouSure: 'Are you sure you want to',
  arrivalRoutines: 'Procedures when arriving',
  asAgreed: 'As agreed',
  assignment: 'Job',
  assignmentHistory: 'Job history',
  availability: 'Availability',
  availabilityAllDays: 'All Days',
  availabilityApplyTo: 'Apply to:',
  availabilityConfirm: 'Confirm Update',
  availabilityDateRange: 'Date Range:',
  availabilityEmptyText1: 'You have not set any availability this week.',
  availabilityEmptyText2:
    'This means that you will miss out on receiving notifications for jobs that matches your profile.',
  availabilityEmptyText3: 'Set your availability now!',
  availabilityErrorMsg: 'You can’t remove availability on days you are booked.',
  availabilityHeader1: 'Tip!',
  availabilityHeader2:
    'If you want to make single-day changes to your availability, you can do that easily in the schedule view.',
  availabilityModalButton: 'Ok, I understand!',
  availabilityModalDescription:
    'The idea with push notifications is to make it easier for you to find jobs. The times you enter as available here are not binding in any way, but mean that you receive push notifications as soon as assignments arrive that match the times you want to work. You then choose whether you want to say yes or no to the jobs.\n\nIf you do not enter availability, the chance of finding assignments can decrease significantly. This is because you will not receive a notice when new jobs become available.\n\nIf you want to be free, leave the availability blank. This way, we do not disturb you with notices when you do not want to work.',
  availabilityModalTitle: 'Find more jobs!',
  availabilityNotavailableText1: 'You’ve marked yourself as unavailable all week.',
  availabilityNotavailableText2:
    'This means that you won’t recieve notifications for jobs that matches your preferences.',
  availabilityNotavailableText3: 'Do you want to change that?',
  availabilitySelected: 'Selected Weekdays',
  availabilitySetAs: 'Set as:',
  availabilityTitle: 'When do you want to work?',
  availabilityUpdated: 'Availability updated!',
  availabilityWarning: 'Availability must be a minimum of 1h.',
  availabilityWarningMsg: 'Adding short intervals might make it difficult to find work.',
  available: 'Available',
  availableExpressJobs: 'Available Express jobs',
  availableJobs: {
    one: '%{count} available job',
    other: '%{count} available jobs',
  },
  availableSchoolsInTheArea: '%{schoolCount} schools in the area',
  availableShortTermOffers: 'Available short-term roles',
  avatarError: 'Error uploading profile picture',
  back: 'Back',
  backModalCancel: 'Go back',
  backModalLeave: 'Yes, leave',
  bankAccountNumber: 'Account number',
  bankClearingNumber: 'Sort code',
  bankDetails: 'Bank Details',
  bankName: 'Bank name',
  beChallenged: 'Challenge and develop yourself',
  beforeTax: 'before tax',
  beforeYouStart: 'Before your assignment 🎬',
  beforeYouStartInfo:
    'You can also prepare yourself by watching a short film about working at %{levelName}.\n\nThe movie is %{videoDuration} min',
  blank: 'Cannot be blank',
  blankOption: 'Check one option',
  blankPassword: 'Mandatory field',
  bonusAmountCanVary:
    'The bonus amount can vary over time, but the bonus specified in our app when your friend creates his account applies as the basis for the bonus payment.',
  booked: 'Booked',
  booking: 'Booking',
  bookingAlertUnavailableDescription:
    "You're registered as absent from this job. If this is correct, you don't have to do anything. If this is incorrect, contact the office immediately.",
  bookingAlertUnavailableTitle: 'Registered absence.',
  bookings: 'Bookings',
  bookingsPlural: {
    one: '%{count} Booking',
    other: '%{count} Bookings',
    zero: '%{count} Bookings',
  },
  bookingWasCancelled: 'This booking was cancelled',
  bookingWithUnpaidTime: 'On this day you have %{unpaidTime} minutes unpaid break.',
  bookInterview: 'Book an interview',
  breakTime: '%{breakTime} minutes break time',
  calendarEventTitle: 'Job at %{schoolName}',
  calendarIsConnected: 'Calendar is connected',
  calendarSynchronizationSuccess: 'Your calendar is updated for next 30 days',
  call: 'Call',
  callPhone: 'Call %{phone}',
  callSchoolUser: 'Call %{name}',
  callUs: 'Call us',
  cameraNotAvailable: 'Camera not available on device',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  cantEditTimeSheet: 'Too late to make changes',
  cantHandleUrl: "Can't handle url",
  changeCountry: 'Change country settings',
  changePassword: 'Change password',
  changeSettings: 'Change settings',
  chatEmpty: "Anything you're \nwondering about?",
  chatEmptyDescription: "Send us a message and we'll help \nyou sort things out!",
  chatWarningInfo: 'The support is currently closed. You can still message us and we’ll get back to you when we open.',
  checkDates:
    'You are about to accept a job. Remember that your acceptance is binding and you can’t change your mind later. With that in mind, please make sure that you are available to work on:\n%{dates}\nbefore you accept.',
  checkDetails: 'Details',
  checkLongTermJob: 'What’s your next job?',
  checkedIn: 'Checked in',
  checkedOut: 'Checked out',
  checkIn: 'Check in',
  checkInDescription:
    'We want you to feel safe about getting the correct pay. If your day is paid hourly you need to check in when your day starts and check out when your day ends. The client will review your timesheet.',
  checkInInfo: 'Pick the time you arrived at the school and started the work day.',
  checkInTitle: 'Why should I check in/out?',
  checkOut: 'Check out',
  checkOutInfo:
    "Pick the time you leaved the school and ended the work day.\nou can't choose an earlier time than the original end time of this job. Please contact the Humly support team if your work shift was shortened.",
  chipError: 'Error',
  chipNotPermitted: 'Not Permitted',
  chipPermitted: 'Permitted',
  chooseFromLibrary: 'Select from library ...',
  chooseTime: 'Choose a time',
  chooseYourBonusSubHeading: 'When your friend (or foe) joins Humly, you choose how the bonus should be paid out',
  chooseYourBonusText: 'Give it all away? 50/50 split? ',
  chooseYourBonusText2: 'Or keep it all for yourself?',
  choseArrivalTime: 'Select your expected arrival time.',
  city: 'City',
  cityEx: "Town/city – E.g. 'Oxford'",
  clientInfo: 'Client Information',
  clientPreferences: 'Client Preferences',
  clientInformation: 'Information about the setting',
  clientProceduresArriving: 'Procedures when arriving',
  clientProceduresLeaving: 'Procedures when leaving',
  clientRoutines: 'Other important routines',
  clientDocuments: 'Important documents',
  close: 'Close',
  closed: 'Closed',
  closeJobsDescription:
    'Choose options below to match with bookings in specific areas or in an entire region. Many supply teachers select their home address or their term-time address, for example. You can change your choices whenever you like. This will only affect your push notifications and your list of matching jobs – you can always see all the available jobs in the general job list.',
  closeMessage: 'Close',
  comment: 'Comment',
  completedJobs: 'Completed Jobs',
  completedJobsPerMonthSince: 'Completed jobs per month since %{date}',
  completedTimesheet: 'Completed timesheet',
  completeRecruitmentProcess: 'Complete the recruitment process!',
  completeRecruitmentProcessDescription:
    'Once all steps of the application process are complete, you will be able to see and apply for all jobs and opportunities through this app.',
  completeTimesheetDescription: 'Complete your timesheet or make changes as needed',
  completeTimesheetSuccessTitle: 'Timesheet Completed!',
  completeYourTimesheet: 'Complete your timesheet',
  compliance: 'Compliance status',
  complianceExpired: 'Compliance Expired | Update required',
  complianceFulfilled: 'You’re all set to work!',
  complianceWarning: 'Compliance expires soon | Update to keep compliant',
  confirm: 'Confirm',
  confirmAppointment: 'Confirm appointment',
  confirmed: 'Confirmed',
  confirmedHeader: 'THE JOB IS YOURS! THE CLIENT HAS BEEN NOTIFIED!',
  confirmedJobs: 'Confirmed Jobs',
  confirmedNorthpassButtonDescription: 'Check out our helpful tips!',
  confirmedNorthpassButtonText: 'Before your job at a %{level}',
  confirmExpress: 'CONFIRM ON-CALL JOB',
  confirmExpressButton: 'Confirm',
  confirmExpressDescription1:
    'We have found this suitable on-call job for you to take today. Read through the details and click on the button below to let the school know you’re coming.',
  confirmExpressDescription2:
    'If you can’t accept the job or can’t make it on time, you need to give us a call here at support. You are expected to be at the school by %{startTime}.',
  confirmExpressOverlayButton: 'CONFIRM CURRENT ON-CALL JOB',
  confirmExpressOverlayDescription:
    'You have been assigned a job for today. You need to accept the job in the app so the school knows you are coming.\n[VIEW AND CONFIRM THE JOB]',
  confirmIcon: 'Confirm!',
  connectCalendar: 'Connect to your calendar',
  connectionLost: 'Connection lost',
  connectionLostDescription:
    'An error occurred when fetching information from our system. It seems like you’ve lost your internet connection. \nPlease, check your connection and try again.',
  contactDetails: 'Contact details',
  contactDetailsAlertTitle: 'Tip',
  contactDetailsAlertBody:
    'You can also send us a message right here within the app. You find the Messages through the app menu.',
  contactDetailsInfo: `We're available all weekdays to assist you. Do you prefer to call or email?`,
  contactDetailsSubtitle: 'Send us an email or call us',
  contactHumly: 'Contact at Humly',
  contactHumlyPlaceholder: 'Please contact the office 💚',
  contactPerson: 'Do you need help during the job? Contact the client',
  contactPersonButtonText: 'Contact the school',
  contactSupport: 'Contact Support',
  contactUs: 'Contact us',
  continue: 'Continue',
  contract: 'Contract:',
  copyLink: 'Copy your link',
  copyPhoneNumber: 'Copy telephone number',
  copyToWholeWeek: 'Copy to whole week',
  county: 'County',
  creditsAcknowledgements: 'Credits and acknowledgements',
  creditsAcknowledgementsDescription: 'Third-party licenses',
  currency: '£',
  currencyLong: '£',
  currentExpress: 'Current On-call job',
  currentPasswordError: 'Wrong current password, try again.',
  dataPolicy: 'Data policy',
  date: 'Date',
  days: '%{days} days',
  deleteAccount: 'Delete Account',
  deleteAccountButton: 'Delete my account',
  deleteAccountRequested: 'Account deletion requested %{date}',
  deleteAccountDescription:
    'If you want to close your account with Humly, you can initiate the process by clicking the button below. We are legally required to hold data about your profile and compliance up to five years after your last completed job. After submitting your request to delete your account, we will review it and decide whether we can delete it immediately or if we need to hold the data for some time before deleting it. We will give you more information about our decision over email. You can find further information in our data policy.',
  deleteApplicationNotPossible:
    'You can no longer remove your application since you have been presented to the school. Please contact the Humly support if you have any questions.',
  deleteApplictionModalDescription:
    'Are you sure that you want to delete your application for this job? You will no longer be able to apply for this specific job again.',
  deleteApplictionModalTitle: 'Delete your application',
  deleteMyApplication: 'Withdraw my interest',
  deleteNotification: 'Delete',
  deliveryMethodOption1: 'Face to face',
  deliveryMethodOption2: 'Online',
  deliveryMethodOption3: 'Pack of work delivered',
  department: 'Nursery department',
  departureRoutines: 'Procedures when leaving',
  descriptionWelcome:
    'Welcome to our app! Here’s a short guide about the app’s most important features. If you can, go through it carefully. But if you don’t have time now you can always come back to it later.',
  desiredWorkloads: 'Preferred workload',
  dateMissing: 'date missing',
  details: 'More',
  differentPassword: 'Your new password cannot be the same as your current password',
  done: 'Done',
  dontExclude: 'Match with this school',
  dontLikeSchool: 'I don’t want to work at this school',
  dontShowAgain: 'Don’t show this message again',
  dontUseForMatching: 'Don’t use for matching',
  downloadPayslip: 'Download payslip',
  duration: 'Duration:',
  hoursLabel: 'Hours:',
  earned: 'Earned 💰',
  edit: 'Edit',
  editAddress: 'Edit address',
  editAvailability: 'Edit availability',
  editAvailabilitySubtitle:
    'The days you leave off will mark you as unavailable. You can change this whenever you want.',
  editBreakModalDescription: 'How many minutes have you had a break today?',
  editBreakModalLabel: 'Choose how many minutes',
  editBreakModalTitle: 'Change your break',
  editionIsBlocked:
    'Name and address are sensitive information and can only be changed by Humly. Please contact us to get help changing your name or address.',
  editTimeSheet: 'Edit your timesheet',
  education: 'What did you last study?',
  educationDescription:
    'Schools often want to know what you studied or what you’re currently studying. What course are you studying now, or did you study last?',
  email: 'Email',
  emailAddress: 'Email address',
  emailCopied: 'Email address has been copied',
  emailCopy: 'Copy email address',
  emailEmployments: 'Employment questions',
  emailJobs: 'Questions about jobs',
  emailSend: 'Send email',
  emailTitle: 'Error report from the app',
  employmentQuestions: 'Employment questions',
  employmentQuestionsSubtitle: 'Employment questions',
  emptyActiveSchoolsDescription: 'Not matching with any previous workplaces.\nAll are excluded from matching.',
  emptyAllOffersListDescription:
    'Check back here early mornings or during \nthe afternoons when most clients \nsubmit new bookings.',
  emptyAllOffersListTitle: 'It’s empty here...',
  emptyExcludedSchoolsDescription: 'No previous workplaces excluded from matching.',
  emptyExpressDescription:
    'No available express shifts just now. \nTip: We typically release new slots one or two \nweeks before they take place.',
  emptyExpressTitle: 'It’s empty here...',
  emptyHistory: 'Nothing to report here',
  emptyHistoryDescription: 'We’ll notify you when there’s new \ntimesheets to report.',
  emptyHistoryUnreported: 'All timesheets cleared!',
  emptyHistoryUnreportedDescription:
    "Isn't that nice? Now it's up to the \nclients and our Finance team to \nkeep up with your pace.",
  emptyReferralsInfo: `(yes or no...but it's yes) Do you have any friends you'd share it with, or keep it all for yourself?`,
  emptyReferralsTitle: 'Do you like free money?',
  emptySchoolsDescription: "Come back here if you've been to a client you \ndon't wish to return to",
  emptySchoolsTitle: 'Nothing to adjust here so far',
  endTime: 'End time',
  enterAddressLine2: 'Enter address line 2',
  enterMandatoryFirstName: 'Mandatory, enter your first name',
  enterMandatoryLastName: 'Mandatory, enter your surname',
  enterMandatoryNationality: 'Mandatory, please select nationality from list',
  enterValidEmailLogin: 'Please enter a valid email address.',
  enterYourEmail: 'Enter your email address',
  equalPasswords: 'New passwords doesn’t match, they must be identical.',
  error: 'Error',
  errorDateAvailability: 'End date must be after start date',
  errorMessage: 'This job was already appointed by another supply teacher and is no longer available.',
  errorReportApplicationFailed:
    'It was not possible to send your application. Try again in a moment. Contact our office if the problem persists and we will help you.',
  errorReportTitle: 'Seems like our servers are taking a break right now…',
  errorSelectedDays: 'Select at least one of the weekdays',
  errorSendingEmail: 'We couldn’t open your email app.',
  errorSendingNotification:
    'Sorry, we can not send push notifications to your phone. Contact us via support@humly.co.uk and we will find out why.',
  errorTimeAvailability: 'End time must be after start time',
  errorTitle: 'Error',
  estimatedSalary: 'Estimated salary',
  exclude: 'Exclude from matching',
  excludedSchoolsTitle: 'Do not match with',
  excludeSpecificClients: 'Exclude specific clients',
  express: 'Express',
  expressAlertUnbookedDescription:
    'Thank you for being available throughout the morning. You will get your guaranteed pay for the day. We hope you take more express jobs in the future.',
  expressAlertUnbookedTitle: 'You didn’t get any job today 👀',
  expressAppointment: 'This booking has been assigned to another supply teacher',
  expressDialogTitle: 'Further information about Express',
  expressDialogText1:
    'Are you a flexible education hero and want to assure you get to work? Then Express jobs is for you!',
  expressDialogSubtitle1: 'How do Express jobs work?',
  expressDialogText2:
    'As an Express Supply you are assigned a job by Humly. You should be ready to receive and confirm your assignment from 6:45AM. You should be prepared to set off to the setting in order to arrive on time. If you risk running late, you must inform Humly right away.',
  expressDialogListTitle1: 'What we expect of You:',
  expressDialogList1Option1: 'Ready to accept and confirm the assigned job in the app from 6:45 AM.',
  expressDialogList1Option2:
    'Accept within maximum 5min response time. (Note: Not responding on time is reported as Invalid Absence and you will not receive the guaranteed pay).',
  expressDialogList1Option3: 'Flexible and available to work the whole day.',
  expressDialogList1Option4: 'Confident in varying jobs within your competence.',
  expressDialogListTitle2: 'What you can expect from Humly:',
  expressDialogList2Option1:
    "Guaranteed full-day pay. If we can't find you a job, you still get paid as long as you respond when we contact you.",
  expressDialogList2Option2: 'Jobs within reasonable commute.',
  expressDialogList2Option3: 'Jobs matching the roles/year groups as described in the express details.',
  expressDialogInfoText:
    'Want to know even more about Express or discuss possibilities? Give us a call, or send us an email!',
  expressExtraInfo: 'Since you were express this day, you are entitled to a half day salary.',
  expressFactTitle: '3 Quick facts about Express supply:',
  expressFactText1: 'Guaranteed pay',
  expressFactSubtext1: 'Full-day guaranteed pay.',
  expressFactText2: 'Humly books the job for you',
  expressFactSubtext2: 'Assigned in the morning from 6:45AM, or the night before.',
  expressFactText3: 'As short commute as possible',
  expressFactSubtext3: 'Max 1h travel time',
  expressJobTitle: 'Express jobs available right now',
  expressSummaryPayment: 'This is your guaranteed pay when signing up for express.',
  expressSummaryReportSubtitle:
    'Since no clients needed your services on this day, you don’t need to complete your timesheet. You will recieve your guaranteed pay automatically with your next payslip.',
  expressSummaryReportTitle: 'No reporting needed',
  expressSummarySubtitle: 'No clients needed your services on this day.',
  expressSummaryTitle: 'Thank you for signing up for express!',
  extent: 'Scope',
  faq: 'FAQ',
  faqSubtitle: 'Perhaps you can find the answer here?',
  feedback: 'Feedback',
  feedbackComment: 'Tell us about your experience',
  feedbackDescription:
    'Your feedback helps us to develop Humly. Schools only see their average rating, so they won’t see your specific response.',
  feedbackDescriptionNew:
    'Your feedback is important to improve the experience for teachers and tutors. The clients only gets a summary of all their feedback and will not see each individual response.',
  feedbackIdeas: 'Opinions and ideas',
  feedbackIdeasDescription: 'We love getting your feedback',
  feedbackIdeasDescriptionModal: 'Do you have any suggestions how we can improve, please send us your opinions',
  feedbackMissingContinueWorking:
    'Let us know whether or not you would like to return to this school so we can improve future matches. Thanks!',
  feedbackTutorQuestion1Label: 'Describe the attendance',
  feedbackTutorQuestion1Title: 'Student attendance:',
  feedbackTutorQuestion2Label: 'What did you focus on?',
  feedbackTutorQuestion2Title: 'Subject(s):',
  feedbackTutorQuestion3Label: 'What resources did you use?',
  feedbackTutorQuestion3Title: 'Resources used:',
  feedbackTutorQuestion4Label: 'Delivery method: *',
  feedbackTutorQuestion5Label: 'Student engagement: *',
  feedbackTutorQuestion6Label: 'Any safeguarding concerns? *',
  feedbackTutorQuestion7Label: 'Please describe the corncerns',
  feedbackTutorQuestion8Label: 'Comment (Optional)',
  feedbackTutorQuestion8Title: 'Any other comments?',
  feedbackQuestion1: 'Lesson plans and job information',
  feedbackQuestion2: 'How was the welcome?',
  feedbackQuestion3: 'How was your support throughout the \nday?',
  feedbackQuestion4: 'Class engagement',
  feedbackQuestion5: 'Would you work at this school again if you had the chance?',
  feedbackSubmitted: 'Thanks for your feedback!',
  feedbackSummary: 'Would you work here again?',
  feedbackTitle: 'How was your day?',
  fetchingError:
    'We are sorry, an error occurred when trying to fetch the information from our system. Please try again.  \n \n If the problem is urgent or keeps occurring, please, contact our support for help.',
  fetchingErrorWithoutUser:
    'We are sorry, an error occurred when trying to fetch the information from our system. Please try again. Contact Humly if the problem remains.',
  files: 'Files',
  findJobs: 'Find Jobs',
  findMyNextJob: 'Find my next job!',
  finish: 'Finish',
  finishGuide: 'Finish guide',
  firstName: 'Name',
  flexible: 'Flexible',
  flexibleStartTime: 'This job has a flexible start time',
  flexibleStartTimeModalInfo:
    'Because this booking starts at %{startTime}, the school is okay with you arriving a little late. Do you think you can make it on time?',
  flexibleTimeInfo:
    'Because this job starts at short notice, the school is okay with you arriving a little late – just so long as they know what time they can expect you. You can specify a time in the next step.',
  forgotPassword: 'Forgot password?',
  frequentlyAskedQuestions: 'Frequently asked questions',
  from: 'from',
  fullBonusTermsAndConditions: 'Full bonus terms and conditions',
  fullDay: 'Full day',
  furtherInfoAboutExpressSupply: 'Further info about Express supply',
  getNotifications: 'Get job notifications',
  giveFeedback: 'Leave feedback',
  giveItAll: 'Give it all 💝',
  given: 'Given 💝',
  gladInterested:
    'Great to hear you’re interested! We’ll be in touch if you are picked for the job. (Are you unable to work all days or is there anything else we should know? Why are you right for this job? Tell us below) *',
  gotIt: 'Got it!',
  goToRecruitment: 'Go to Recruitment',
  goToSettings: 'go to settings',
  grades: 'Year groups',
  great: 'Great!',
  guide: 'Guide',
  guideSubtitle: 'Quick guide to how the app works',
  guideTitle: 'App Guide',
  hasBeenAdded: 'has been added to your jobs',
  hi: 'Hi %{userName},',
  hide: 'Hide',
  historicNotifications: 'Historic',
  history: 'History',
  historyEmpty: "You haven't completed your first job yet",
  historyEmptyDescription: 'Time to book that first one and begin your Humly career',
  holidayPay: 'Holiday Pay',
  holidayPayment: '(including %{salary} holiday)',
  homeAddress: 'Address',
  hour: 'hour',
  hourlyPay: 'Hourly pay',
  hours: 'hours',
  hoursFirstLetterUp: 'Hours',
  hoursIn: 'hours in',
  hoursPerWeek: 'hours per week',
  hoursWithValue: 'Hours: %{hours}',
  hoursPlural: {
    one: '%{count} hour',
    other: '%{count} hours',
    zero: '%{count} hours',
  },
  hoursShort: 'h',
  howItWorks: 'How it works',
  howItWorksChipText: 'Humlys Referral Program',
  howItWorksHeading: 'Here’s all you have to do',
  howWasYourDay: 'How was your day?',
  humlyAcademy: 'Humly Academy',
  humlyAcademyDescription: 'Safeguarding training and other relevant courses',
  humlySupport: 'Humly Support',
  iAmSure: 'I’m sure',
  ignore: 'Decline',
  ignoreOfferTitle: 'Remove this job from the list?',
  ignoreOfferDescription:
    'This action will let Humly know you’re not interested in this job and remove it from the list.\n\nIf you are unsure, you can cancel and use the button in the top-left to return to the list without making a decision now.',
  ignoreConfirmationButton: 'Yes, remove!',
  importantFiles: 'Important documents',
  inactiveUser: 'Inactive user',
  inactiveUserDetails:
    'Your user profile is inactive. Förmodligen saknar du ett giltigt belastningsregister. Contact support for more info. Tel: 02081254590',
  includeAddressForMatching: 'Use my home address for matching',
  includeRegionIsSearchLocation: 'Your home region.',
  info: 'Info',
  informationMissing: 'Information is missing',
  initialPushNotification: 'We can send you push notifications! 🎉',
  interest_accepted: '%{schoolName} want to give you the job! Confirm immediately in the app.',
  interest_rejected:
    'Unfortunately a job you applied for is no longer available. Check the app for other opportunities!',
  interest_shown: 'You applied for the job at %{schoolName}',
  interested: 'Interested',
  interview_booked: 'Video interview',
  interview_bookedDescription:
    'In this step, we want to get to know you better over a video interview. The interview will be 20-30 minutes long. Once you have scheduled your video interview, you will get all the details.\n\nYou can join the video call directly through your phone or computer without downloading any apps. We are looking forward to getting to know you better!',
  internalError: 'Internal server error',
  internalErrorDescription: 'An error has occurred.\nPlease try again later :)',
  invited: 'Invited? Log in',
  invited_loops: 'Invited to Loops',
  invited_loopsDescription:
    'Before the interview, run through the introduction to Humly via Loops. It’s simple and takes no more than 30 minutes to complete. Loops is a digital learning platform and our way to help you prepare as best as possible for your interview and your future career as a Humly supply teacher. Follow the link we sent you via email or SMS to access Loops. \n\nAs soon as you create an account and log in to Loops you can start the course. The last page of the course contains a link to book an interview. If you already have a Loops account, use the link below to go directly to the training course\n\nHope to see you soon!',
  inviteFriend: 'Invite a friend',
  inviteOtherBrilliantEducatorsSubHeading: 'Invite other brilliant educators!',
  inviteOtherBrilliantEducatorsText: 'Share your link to many as you want to.',
  inviteOtherBrilliantEducatorsText2: 'We offer £100 for each friend (or foe).',
  iosNotificationModalButton: 'Sounds great!',
  iosNotificationModalDescription:
    'We have improved our push notifications that notify you about great job opportunities. You will be asked to accept that Humly have the right to send you push notifications. This is an essential feature of the app, so please accept it. 🥳',
  iosNotificationModalTitle: 'Allow Notifications',
  iosRateUs: 'Give us five stars!',
  iUnderstand: 'I understand!',
  job_appointed: 'You were appointed for a job at the client %{schoolName}.',
  jobAcceptCheckboxLabel: 'I confirm that I can work the full booking.',
  jobAcceptExpressCheckboxLabel: 'I confirm that I can work the full booking.',
  jobAcceptTitle: 'You are about to accept this job:',
  jobApplyCheckboxLabel:
    'I confirm that I can work the full booking and I understand that I must work if I get the job.',
  jobApplyTitle: 'You are about to apply for this job:',
  jobDescription: 'Job description',
  jobDetails: 'Job details',
  jobIsYours: 'The job is yours! ✌️',
  jobOfferBreakTitle: 'Unpaid break',
  jobOfferContactDescription: 'Don’t hesitate to contact us!',
  jobOfferContactTitle: 'Do you have any questions?',
  jobOfferHeaderAvailable: 'Available Job',
  jobOfferHeaderCanceled: 'Cancelled Job',
  jobOfferHeaderConfirmed: 'Confirmed Job',
  jobOfferHeaderConfirmedExpress: 'Confirmed Job',
  jobOfferHeaderExpressAvailable: 'Available Job',
  jobOfferHeaderNoAvailable: 'No Longer Available',
  jobOfferHeaderPending: 'Pending Application',
  jobOfferHealthTitle: 'Healths or safety risks',
  jobOfferHideBookings: 'Show less',
  jobOfferIgnore: 'No, not for me!',
  jobOfferIgnoreDescription:
    'This action will let Humly know you’re not interested in this job and remove it from the list.\n\nIf you are unsure, you can cancel and use the button in the top-left to return to the list without making a decision now.',
  jobOfferIgnoreTitle: 'Remove this job from the list?',
  jobOfferInformationTitle: 'Information',
  jobOfferLunchTitle: 'Lunch time',
  jobOfferPayTitle: 'Pay Rate',
  jobOfferReplacementTitle: 'Replaced Teacher',
  jobOfferRoomTitle: 'Room',
  jobOfferRoutinesDescription: 'Key Information about the setting',
  jobOfferRoutinesTitle: 'Routines & Procedures',
  jobOfferShowBookings: 'Show all days',
  jobOfferWithdrawButton: 'Yes, withdraw!',
  jobOfferWithdrawConfirmation: 'Are you sure you want to withdraw your interest in this job?',
  jobOfferWithdrawTitle: 'Not interested anymore?',
  jobOfferWorkingDays: '%{days} days',
  jobs: 'Jobs',
  jobsClose: 'Jobs nearby',
  jobsCompletedInMonthInfo:
    'Note that only completed jobs are included in this summary. Any jobs that have been cancelled, you’ve been on sick-leave or for some other reason wasn’t completed are not included.',
  jobsListMatching: 'Jobs Matching your preferences ✅',
  jobsListMatchingEmpty:
    'Currently, there are no open jobs perfectly matching your availability and preferences. But what about almost perfectly? 👇',
  jobsListMoreOffersTitle: 'More Available jobs - ApPly now',
  jobsListNotMatching: 'Other jobs that may suit you?',
  jobsListRequestedTitle: 'you’ve been requested! 💚 🦸',
  jobSubmitInterest: 'Apply',
  jobSummary: 'Job summary',
  landPhone: 'Landline Phone',
  lastName: 'Surname',
  lastYear: 'Last Year',
  leaveFeedback: 'Leave feedback',
  legalNotices: 'Legal notices',
  legalNoticesDescription:
    'This application relies on various open-source libraries within its codebase. We take pleasure in acknowledging and giving due credit to the contributors listed below.',
  level: 'Level',
  linkCopied: 'Link copied to clipboard!',
  loading: 'Loading...',
  loadingLevelsAndSubjects: 'Loading year groups and subjects',
  loadMore: 'Load more',
  login: 'Log in',
  loginEmailError:
    'We could not find an account with this email address. Please check your email address and try again.',
  loginError: 'Invalid email address or password',
  loginHeader1: 'Discover',
  loginHeader2: ' jobs ',
  loginHeader3: 'in\neducation and\nearly years',
  loginPasswordError:
    'Oops! Your password appears to be incorrect. Please try again. You can reset your password via the button below.',
  logOut: 'Log out',
  long: 'Long',
  longAssignment: "I'm interested in permanent contracts.",
  longTermFactTitle: 'What do we mean by long-term jobs?',
  longTermFactText1: 'From a couple of weeks to perm. contracts.',
  longTermFactSubtext1: 'Focus your efforts in one setting.',
  longTermFactText2: 'Thorough matching process',
  longTermFactSubtext2: 'We interview continuously to find the best match for the job.',
  longTermFactText3: 'Certainty of being booked in on jobs.',
  longTermFactSubtext3: 'Schedule for the entire contract period with the booking confirmation.',
  longTermTitle: 'Now we’re searching for the right \nEducator for these assignments!',
  longTermOfferApplicationHeader: 'Available Long-Term Job',
  longTermOfferPendingHeader: 'Pending application',
  longTermOpportunities: 'Long-Term Jobs',
  longTermPending: 'Pending Long-Term Jobs',
  longTermPerm: 'Long-Term/Perm',
  lunchPrice: 'You’ll eat lunch at the school',
  lunchPriceText: 'Price: %{lunchPrice} deducted from your pay',
  makeChanges: 'Make changes',
  makeDifference: 'Make a difference',
  markAsRead: 'Mark as read',
  matching: 'Preferences',
  matchingInfoTooltip: 'Based on your availability, geographical matching, levels and subjects.',
  menu: 'Menu',
  message: 'Message',
  messageFromStudentvikarie: 'Message from Humly!',
  messages: 'Messages',
  messagesSubtitle: 'Write us a message',
  minutes: 'minutes',
  missingFields: 'Missing info:',
  missingPreferencesDetails: 'We are missing the information below',
  missingProfileDetails: 'We are missing some profile details',
  missingProfilePreferencesDetails: 'We are missing the information below',
  modalWeakConnectionMessage: 'You have weak Internet connection. Please try again',
  moneyRiseInfo: '+ %{money} since last month',
  month: 'Month',
  more: 'more',
  multiDay: 'Multiday',
  must: 'MUST',
  myEarnings: 'My earnings',
  myEarningsDescription: 'These numbers are estimates and may differ from your actual pay. ',
  name: 'Name',
  negotiable: 'Negotiable',
  newMessage: 'New message!',
  newNotifications: 'New',
  newPassword: 'New password',
  newVersion: 'New app version available!',
  newVersionDescription: 'Update the app now to get any new features and improvements.',
  next: 'Next',
  no: 'No',
  noAccount: 'Don’t have an account?',
  noAdded: 'Not added',
  noAreas:
    'You will match and receive notifications from all schools in %{regionName}. Add an area to only match with schools that are close to you.',
  noAvailableAssignments: 'No current job applications',
  noAvailableAssignmentsDescription: "Perhaps it's time to apply for some new \nopportunity?",
  noAvailableProposals: 'No openings right now',
  noAvailableProposalsDescription: 'Perhaps some short-term assignments can be of interest to you in the meantime?',
  noAvailableRequests: 'There are no available short-term jobs matching your profile at the moment',
  noCompany: 'NO COMPANY',
  noMatchingBookings: 'Unfortunately we haven’t got any jobs that match your availability and preferences right now.',
  noMessages: 'You can see your messages from Humly here. Your inbox is empty right now.',
  noNotificationsDescription: "We'll notify you when there's news \nto keep track of.",
  noNotificationsTitle: "You're up to date!",
  noRegion: 'NO REGION',
  noSchedule: "I'm interested in long-term jobs spanning multiple weeks at the same client.",
  noSuggestions: 'No suggestions',
  notAvailable: 'Unavailable',
  noAvailable: 'Not Available',

  notCompleteAssignments: 'You haven’t completed any jobs this month.',
  notificationError:
    'Our system identified that we can not send push notifications to your phone. Try to reset notifications in your profile details on the app.',
  notifications: 'Notifications',
  notificationsAppointedTitle: 'You were appointed!',
  notificationsAppointedSubTitle: 'You were appointed for a job at %{school}',
  notificationsAcceptedTitle: 'You applied for a job!',
  notificationsAcceptedSubTitle: 'You applied for a job at %{school}',
  notificationDeletingError: 'Deletion failed, please try again',
  notificationsModalCancel: 'Cancel',
  notificationsModalDelete: 'Delete this notification',
  notificationsStatusChangeError: 'Change failed, please try again',
  notificationsModalMore: 'Show more',
  notificationsModalRead: 'Mark as read',
  notificationsModalUnRead: 'Mark as unread',
  notificationsCampaignLink: 'Open link',
  notificationsCampaignTitle: 'Humly wrote you',
  notificationsShownTitle: 'Confirm your job application!',
  notificationsShownSubTitle: '%{school} want to give you the job! Please confirm immediately in the app.',
  notificationsRejectedTitle: 'Booking no longer available',
  notificationsRejectedSubTitle:
    'Unfortunately a job you applied for at %{school} is no longer available. Check the app for other opportunities.',
  notificationsProposalClosedTitle: 'Long-term job no longer available',
  notificationsProposalClosedSubTitle:
    'A long-term job you applied for is no longer available. Click here to read more.',
  notWorkedOvertime: 'Yes! Check out',
  noUpcomingBookings: 'No upcoming jobs…',
  noUpcomingBookingsDescription:
    "That's no way to go, jump over to the Find \nJobs tab instead and get the jobs you want!",
  numberOfClientsInArea: '%{quantity} schools and nurseries in your area',
  numberOfClientsInAreaTooltip: 'You will be notified about matching jobs within your selected area.',
  occasionalWork: 'Short term temporary work',
  officialPaySlipInfo:
    'The numbers above are estimations. The official payslip will be sent to you over email when you get paid. Contact us if you have any questions',
  offerNotAvailable: 'This job is no longer available.',
  offerNotAvailableDetails:
    'This job is no longer available. Thank you for your application. Go to ‘Find Jobs’ to see more opportunities.',
  offerNotAvailableTitle: 'No Longer Available',
  OK: 'OK',
  okGo: 'Okay, go!',
  oldPassword: 'Current password',
  onWhichGroups: 'What Year Groups do you prefer working with?',
  onWhichLevel: 'What type of jobs do you prefer?',
  onWhichSubjects: 'Subjects you would like to teach',
  open: 'Open',
  openGuide: 'Quick guide to how the app works',
  openHours: 'Open %{hours}',
  openingHours: 'Humlys opening hours',
  openLink: 'Open link',
  openMap: 'Open map',
  openSettings: 'Open settings',
  openSourceInfo: 'Information about open source code',
  order: 'More',
  orderBookingInfo: 'NB! This job is part of a multi-day job.',
  orderIncludeInfo: 'This multi-day job is made up of the following days and times',
  orderNotice: 'NB! You must be able to work all shifts in order to accept this job.',
  orderSalaryFullDay: 'Full day: %{salary}',
  orderSalaryAM: 'Full day: %{salary}',
  orderTitle: 'Multi-day job',
  orderBookingsSubtitle:
    'This job is made up of the following days and times. You must be able to work all shifts in order to accept this job.',
  other: 'Other',
  otherAddresses: 'Other addresses',
  otherJobs: 'Other available jobs',
  otherPlans: 'Not available to work these hours',
  otherRoutines: 'Other important procedures',
  overtimeApprovingPersonLabel: 'Did anyone approve this? Who?',
  overtimeDatePickerLabel: 'What time did your shift end?',
  overtimeExplanationLabel: 'What did you do during your overtime?',
  overtimeInfo:
    'Answer these questions to clarify your time report. The school will have to confirm your reported times.',
  overtimeShiftLabel: 'Why was this changed?',
  overtimeShiftPickerLabel: 'Was your shift AM, PM or All day?',
  password: 'Password',
  passwordConfirmation: 'Confirm new password',
  passwordConfirmationFailed:
    'You entered the invalid password.\nPlease correct it and submit bank details again.\n\nIf the problem continues, you can contact the Humly support.',
  passwordConfirmationRequired: 'You need to type your password to do these changes.',
  pay: 'Pay',
  payByEstimatedPeriod: 'Your estimated pay for this period is %{payRate} 👏',
  payByPeriod: 'Your pay for this period was %{payRate} 🏆',
  payByPeriodDescription: 'You will receive the pay on the Friday following the week you worked.',
  payRate: 'Pay Rate:',
  payslip: 'Payslip',
  payslips: 'Payslips',
  pendingInterests: 'Pending Applications',
  perHour: 'per hour',
  permanentVacancy: 'Permanent',
  permissionDenied: 'Access denied',
  permissionDeniedInfo: 'Needed to take photos with your camera and to select pictures from your image library.',
  phone: 'Telephone',
  phoneCopied: 'Telephone number has been copied ',
  phoneError: 'Enter phone number, only digits 0-9',
  pinCodeDescription:
    'Would the school like you to come back for another day? Use this pin code to approve the school’s extension of your job.',
  pinCodeRedeemedBy: 'Booked using pin code',
  pinCodeTitle: 'Pin code',
  pleaseTryAgain: 'Please try again :)',
  PM: 'PM',
  preferences: 'Preferences',
  preparationTime: 'Preparation time',
  prev: 'Back',
  previous: 'Previous',
  primarySchool: 'Primary school',
  profile: 'Profile',
  profileDescription: 'Describe yourself',
  profileEducation: 'Education',
  profileImageWarning:
    'Your profile picture will be visible to schools so be sure to pick a professional photo. The picture should represent what you are like when you are at work. Avoid photos with filters of any kind. Thanks. :-)',
  progress: 'Progress',
  proposal: 'Registration of interest',
  proposal_interest_accepted: 'School want to give you the job! Confirm immediately in the app.',
  proposal_interest_rejected:
    'Unfortunately a job you applied is no longer available. Check the app for other opportunities.',
  proposal_interest_shown: 'You applied for the job.',
  proposal_job_appointed: 'You were appointed for a job at the school.',
  proposals: 'Registration of interest - Longer assignments',
  pupilDocuments: 'Documents',
  pupilHeader: 'Pupil',
  pupilInformationHeader: 'Information',
  pupilInformationNew: 'More information about the pupil will be provided to the appointed candidate',
  pupilInformationPast:
    "For security reasons we do not show anything more than the pupil's name after a completed job.",
  pushNotificationsError: `An error occured when controlling you push notifications. Please verify you have granted the Humly app permissions to send you push notifications in your phone settings. If this doesn't do the trick, you can try resetting the push notifications below.`,
  pushNotificationsNoPermissions:
    'We cannot send you notifications. Please, give app permissions to receive notifications.',
  pushNotificationsNotPermitted: `Please verify you have granted the Humly app permissions to send you push notifications in your phone settings. If this doesn't do the trick, you can try resetting the push notifications below.`,
  pushNotificationsPermitted: 'We can send you push notifications!',
  pushNotificationsSettings: 'Push Notification Settings',
  pushNotificationsStatus: 'Push Notification Status:',
  quitGuide: 'Quit guide',
  rate: 'h/week',
  rateUs: 'Enjoying our app?',
  rateUsStore: 'Give us five stars in App Store!',
  readMore: 'Read more',
  realIndependence: 'True freedom',
  recruitment: 'Recruitment',
  referralAnnouncementBodyPartOne:
    'Invite other brilliant educators! And when they join Humly, you choose how the bonus should be paid out. Give it all away? 50/50 split? Or keep it all for yourself?',
  referralAnnouncementBodyPartTwo:
    'Once they work their first day, we´ll pay the bonus the following Friday. Keep track of your givings and earnings and see if you’re a real friend or foe 😈',
  referralAnnouncementOverline: 'Humlys Referral program',
  referralAnnouncementPrimaryActionTitle: 'Start referring',
  referralAnnouncementTitle: 'Refer a Friend (or Foe)',
  referralAppliedInfo: 'Applied: %{date}',
  referralBonusNeedsToMeetConditions:
    'For your referral bonus to be paid out, you and your friend must meet all of these conditions:',
  referralPaidOutInfo: 'Bonus paid out: %{date}',
  referralSectionEducatorAccepted: 'Accepted & Ready to work',
  referralSectionEducatorInRecruitment: 'In recruitment',
  referralSectionEducatorInRecruitmentInfo: 'Select how the bonus should be paid out',
  referralSectionEducatorLeftHumly: 'Left Humly',
  referralSectionPaidOut: 'Bonus paid out',
  referrals: 'Refer a Friend or Foe',
  referralShareInfo: `Hey, I wanted to tell you about Humly. They empower great educators with full career control (that's you). And if you join and work just one single day, they'll pay you up to a £100 bonus the following week! Join here: %{link}`,
  referralShareInfoTitle: 'Have you heard about Humly?',
  referralsInfo: 'With Refer a Friend or Foe, you choose\nhow the £100 bonus gets paid out.',
  referralsMenuInfo: 'Up to £100 bonus for every referral',
  referralsModalTitle: 'How do you want the £100 bonus to be paid out to %{name}?',
  referralsSubInfo: 'Give it to them? Split it? Keep it all? Decide once your friend (or foe) applies to Humly.',
  reject: 'Reject',
  rejectedDescription: 'The schools has chosen to appoint another teacher for this job.',
  rejectedIcon: 'No longer available',
  rejectedTitle: 'This booking is no longer available.',
  rejectJob: 'Decline',
  rejectModalTitle: 'Help us to find you better matches – why did you turn this job down?',
  rejectProposal: 'No, not for me!',
  relationship: 'Relative’s relationship to you',
  relativeName: 'Relative’s name',
  relativeNameDescription:
    'For security reasons, we need the contact details for a close relative of yours. We will only contact your relative in the case of an emergency.',
  relativePhone: 'Relative’s telephone number',
  remove: 'Remove',
  removeAddressWarning: 'Are you sure you want to remove this area?',
  removeAvailabilityWeek: 'Remove availability this week',
  report: 'Report',
  reported: 'Reported',
  reportError:
    'We are sorry, an error occurred when trying to fetch the information from our system. Please try again.  \n \n If the problem is urgent or keeps occurring, please, contact our support for help.',
  reportTime: 'Report time',
  reportTimeBreak: 'How long was your break?',
  reportTimeEnd: 'When did you finish working?',
  reportTimeEndError: 'The departure time cannot be set before  your arrival time.',
  reportTimeModified: 'Updated by you',
  reportTimeOvertimeReason: 'What did you do during your overtime?',
  reportTimeOvertimeRequestBy: 'Who approved of your overtime?',
  reportTimeStart: 'When did you start working?',
  reportTimeStartError: 'The arrival time cannot be set after your departure time.',
  reportTimeTitle: 'Edit your timesheet',
  reportTokenOutdatedDescription:
    'Your active session expired since you haven’t been active for a while. Please log in again 😊',
  reportTokenOutdatedTitle: 'You’ve been logged out',
  requested_by_school: 'You were requested for a job',
  requiredField: 'This field is required',
  requiredFields: 'Please complete all required fields',
  resetPassword: 'Reset password',
  resetPasswordBackButton: 'Back to Log in',
  resetPasswordButton: 'Reset my Password',
  resetPasswordConfirmationHeader: 'Check your inbox',
  resetPasswordHeader: 'Forgot your password?',
  resetPasswordInfo: 'No worries, we’ll send you instructions on how to reset it.',
  resetPasswordConfirmation:
    'Your password was reset! We have sent you a temporary password and further instructions over email.',
  resetPasswordError:
    'We could not find an account with that email address. Please check your email address and try again, or contact our support for further help.',
  resetPushToken: 'Reset my push notifications',
  retry: 'try again',
  required: 'Required',
  routines: 'Procedures',
  salary: 'Salary',
  salaryInfo: 'Here you can see your historical jobs and the number of worked hours per month.',
  salaryLabel: 'Salary:',
  salaryView: 'Salary',
  salutation: 'Salutation',
  save: 'Save',
  saveChanges: 'Save changes',
  saveLocation: 'Save area',
  schedule: 'Schedule',
  school: 'Client',
  schoolCanBookYou:
    'Your selected schools (under Schools) can book you directly for these times without you needing to accept.',
  schoolCancelledJob: 'The client cancelled this job',
  schoolChosenElse: 'The job is no longer available.',
  schoolListDescription:
    'Once you have chosen not match with certain clients, you will no longer see available jobs from them. \n\nPlease note that only you and our team at Humly have access to your selected workplaces. The school or preschool itself will not have access to this information. \n\nIf there were specific issues at the workplace that led to your decision, we kindly request that you reach out to us. Your feedback is crucial as it helps us address these concerns and work towards creating a better working environment for everyone in the future.',
  schoolListInfo: 'Select the previous workplaces you would like to remain matched with.',
  schoolListKeepMatching: 'Keep matching with',
  schools: 'Clients',
  searchDistance: 'Distance – How far are you willing to travel?',
  secondarySchool: 'Secondary school',
  seeVacancies: 'See vacancies',
  select: 'Select',
  selectAvatar: 'Select profile picture',
  selectionNoLongerAvailable: 'Selection no longer available',
  send: 'Send',
  SENSkills: 'SEN Skills',
  serverMaintenanceWarningDescription:
    'We are currently performing essential maintenance on our service. This maintenance is necessary to ensure continued optimal performance and security. We expect the maintenance to be completed by %{migrationEstimatedTime}.\n\nAs a result of this maintenance, the Humly app will be temporarily unavailable. We apologise for any inconvenience this may cause and appreciate your understanding.',
  serverMaintenanceWarningTitle: 'The Humly App is currently unavailable',
  serverUnavailable:
    'Please try again in five minutes. Contact us by telephone at 01908662655 or by email at support@humly.co.uk if the problem continues and you need help fast.',
  serverUnavailableTitle: 'Our server is currently down.',
  setAvailability: 'Set availability',
  setPhoto: 'Select profile picture',
  share: 'Share',
  sharedMedicalNotes: 'Shared Medical Notes',
  sharedMedicalNotesInfo:
    'The following information is made available to schools or nurseries where you undertake an assignment. If you would like to change this information please get in contact with us.\n\nWe have a duty of care to provide certain relevant information to keep you and the children under your care safe, if reasonable adjustments need to be made to your working environment, or if relevant for Health & Safety reasons, i.e. disability or allergy. For full details of how we process your personal data, please click %{link} to review our Privacy Policy.',
  shiftChange: 'No I worked on different shift',
  short: 'Requests',
  shortTermPending: 'Pending Short-term jobs',
  show: 'Show',
  showBooking: 'Show booking',
  showLess: 'show less',
  showMoreMonths: 'Show more months...',
  shownInterest: 'You have expressed an interest in this job',
  showPasswords: 'Show password',
  simple: 'One day',
  sinceStarting: 'Since starting',
  skip: 'Skip',
  smartTechnology: 'Smart technology',
  smsMessage: 'Sent as SMS',
  snackbarAppointedMessage: 'Yay! The job is yours.   ',
  snackbarDisconnectedMessage: 'Your phone is not connected to the Internet.',
  somethingWentWrong: 'Something went wrong.',
  splitEvenly: '50/50 Split 😇',
  startTime: 'Start time',
  street: 'Street',
  studentEngagementOption1: 'Fully engaged',
  studentEngagementOption2: 'Partially engaged',
  studentEngagementOption3: 'Unengaged',
  studentvikarieContactAppointedTitle: 'Sick? Other issues/questions? Contact Humly',
  studentvikarieContactButtonText: 'Contact us',
  studentvikarieContactTitle: 'Questions? Contact Humly',
  studentvikarieProposalContactTitle: 'Got a question? Contact us!',
  subject: 'Subject',
  subjects: 'Subjects',
  submitFeedback: 'Send feedback',
  submitReport: 'Submit Report',
  submitTimesheet: 'Submit Timesheet',
  summary: 'Summary',
  support: 'Support',
  supportCommonQuestions: 'Common questions',
  supportEmail: 'support@humly.co.uk',
  supportHelpNote: 'Our support team can help you with any questions about a specific job or your position.',
  supportHours: 'Monday - Friday, 7am - 6pm',
  supportHoursTitle: 'Opening hours',
  supportPhone: '020 812 54 590',
  supportTitle: 'How can we help you?',
  sweden: 'Sweden',
  takeItAll: 'Take it all 😈',
  takePhoto: 'Take a photo ...',
  takeToSeparatePage:
    'This will take you to a separate page and you will need to log in to access your official payslip.',
  teacherReplaced: 'Original teacher',
  temporaryLongTermBooking: 'Temporary Long-term',
  termsAgreement: 'Approve terms',
  termsAndConditions: 'Terms and conditions',
  thankYou: 'Thanks for your input! 🙌🏽',
  theBonusIsTaxed: 'The bonus is taxed as earned income at the same rate.',
  thisHappensNow: 'Here’s what happens next',
  thisYear: 'This year',
  time: 'Time',
  timeFormat: 'h:mm A',
  timeMustBeAfter: 'Time must start at %{minimumDate}',
  timeMustBeBefore: 'Time must start before %{maximumDate}',
  timeMustBeBetween: 'Time must be between %{minimumDate} and %{maximumDate}',
  timesheet: 'Timesheet',
  timesheetBackModalDescription: 'The changes you’ve made to your timesheet won’t be saved.',
  timesheetBackModalTitle: 'Leave without saving?',
  timesheetMenu: 'Timesheet',
  timesheetReport: 'Report',
  timesheetReported: 'Done',
  title: 'Title',
  titleEx: "Title – E.g. 'Home'",
  titleWelcome: 'Welcome!',
  to: 'to',
  toBeDecided: 'To be decided',
  today: 'Today',
  todaysReplies: "Today's replies",
  toFewHours: 'Not enough hours',
  tomorrow: 'Tomorrow',
  tonight: 'Tonight',
  tooLongTravel: 'Too far away',
  toPreferences: 'Go to preferences',
  total: 'Total',
  totalTime: '%{hours} hours, %{minutes} minutes',
  totalTimeCommon: '%{hours} h %{minutes} m',
  totalTimeShort: '%{hours} hrs %{minutes} mins',
  town: 'Town',
  tutorFeedbackFormHelperText:
    'Please, fill out all fields marked with an asterisk (*) to enable submitting the report.',
  tutoringReport: 'Tutoring Report',
  typeMessage: 'Type a message',
  unitedKingdom: 'United Kingdom',
  unpaidTime: 'Unpaid break',
  unpaidTimeInfo: 'Total break during the day',
  unreported: 'To be reported',
  upcoming: 'Upcoming',
  update: 'Update',
  updateNow: 'Update now!',
  updatePreferencesCampaignTitle: 'Update your work preferences for next term 👇',
  updatePreferencesCampaignDescription:
    'Are you interested in booking a long-term role next term? \n\nWill your current preferred work schedule change? Want more hours or less? \n\nUpdate your work preferences so we can match you with the right assignments next term.',
  uploadError: 'Error uploading file',
  upToAmountOnEveryInvite: 'Up to £100 bonus for every referral',
  useForMatching: 'Used for matching',
  validationError: 'Complete all required fields',
  version: 'App version',
  viewAll: 'View all',
  viewProfile: 'View profile',
  waitForResonse: 'Waiting for reply.',
  waitingForConfirmation: 'Read and confirm as soon as possible',
  waitingForResonse: 'Waiting for reply',
  waitingForResponseTitle: 'Waiting for response from school...',
  waitingIcon: 'Awaiting reply',
  weAreSorryCancelled:
    'We are sorry but the client has cancelled this job. Try to find a new one or contact us for help!',
  webRateUs: 'Give us five stars!',
  week: 'week',
  welcome: 'Welcome',
  welcomeDescription1:
    'Welcome to a whole new world of opportunities! With Humly you decide when and where you work. Say goodbye to nagging bosses and discover what true freedom feels like.',
  welcomeDescription2:
    'No management training in the world can measure up to the thrill of leading and inspiring a whole class. We will train and coach you on your journey as a flexible supply teacher.',
  welcomeDescription3:
    'There are few things in this world as rewarding as giving students those a-ha moments and helping them to see new perspectives. Seize your chance to become a force for good in the classroom.',
  welcomeDescription4:
    'Set your preferences and get notifications on your phone whenever we get a job that matches your profile. Simple and easy!',
  willMakeInTime: 'I will make it on time.',
  withoutBreakTime: 'No break time',
  workAtleastOnce: 'Your friend must have worked at least once.',
  workedOvertime: 'No I worked overtime',
  workFirstGetBonusAfterSubHeading: 'Once they work their first day, we`ll pay the bonus the following Friday.',
  workFirstGetBonusAfterText: '(Note that you too must have worked at least one job, in order to receive the bonus)',
  workPreferences: 'Work Preferences',
  writeMessage: 'Write us a message',
  wrongLevels: 'Wrong year groups',
  wrongSubjects: 'Wrong subjects',
  year: 'Year',
  yearGroupNursery: 'Nursery',
  yearGroupReception: 'Reception',
  yearGroupKeyStage: 'Key Stage %{number}',
  yes: 'Yes',
  youAreNotCompliant: 'You are not compliant for this assignment.',
  youAreRequested: 'You were requested for this job',
  youAreRequestedTooltip: 'Clients can request multiple candidates. The first candidate to accept the job gets it.',
  youCanStillInvite:
    'You must have worked at least once. Note that you can invite friends before you have started working. The first time you work, you will be paid potential accrued bonuses.',
  youDecideYourCut:
    'You decide for yourself if you want to share the bonus with your friend, if you want to take the entire bonus or if you want to give the entire bonus away. This can be selected until your friend has been assigned their first mission. It is not possible to change this afterwards.',
  youLeftFeedback: 'Thanks for your feedback!',
  youMustBeHired: 'You must still be employed at the time your friend starts working.',
  yourAreas: 'Your areas',
  yourAreasDescription: 'You will be matched with clients in these areas',
  yourCompletedJobs: 'Your Completed Jobs',
  yourJobEndedAt: 'Your job today ended at %{time}',
  yourJobType: 'Your job today was %{type}',
  yourReferrals: 'Your referrals',
  yours: 'Your jobs',
  youWorked: 'You have worked',
  zip: 'Postcode',
  zipCodeError: 'Please enter a valid zip code',
  zipEx: 'Postcode',
  thankYouForApplyingDescription:
    "Here's what happens next. We will review your application and get back to you by phone or email with next steps. Please don't hesitate to get in touch if you have any questions.",

  ...onboardingEn,
}

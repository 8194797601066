import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { View, StyleSheet } from 'react-native'

import { Error, Label } from 'src/components/Form/Common'
import translations, { translate } from 'src/utils/translations/translations'

import styles from './styles'
import Radio from './Radio'

const defaultOptions = [
  {
    value: true,
    label: translate(translations.yes),
  },
  {
    value: false,
    label: translate(translations.no),
  },
]

export const InputRadioGroup = props => {
  const {
    containerStyle,
    field: { name, value },
    form: { errors, touched, setFieldValue },
    label,
    labelStyles,
    options = defaultOptions,
    required,
  } = props

  const isTouched = get(touched, name)

  const handleChange = value => {
    setFieldValue(name, value)
  }

  const isErrors = errors && Object.keys(errors).length

  return (
    <View style={StyleSheet.flatten([styles.radioGroupContainer, containerStyle])}>
      {label && <Label labelStyles={labelStyles} label={label} required={required} />}
      {options.map(option => (
        <Radio
          key={option.value}
          onPress={() => handleChange(option.value)}
          value={option.value}
          label={option.label}
          selected={option.value === value}
        />
      ))}

      {isErrors ? <Error errors={isTouched ? errors : {}} name={name} /> : null}
    </View>
  )
}

export default InputRadioGroup

InputRadioGroup.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
  }).isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  labelStyles: PropTypes.object,
  required: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import styles from './styles'

export const Radio = props => {
  const { selected, onPress, label } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      style={
        selected
          ? StyleSheet.flatten([styles.radioButtonContainer, styles.radioButtonContainerSelected])
          : styles.radioButtonContainer
      }
    >
      <View
        onPress={onPress}
        style={!selected ? styles.radioButton : StyleSheet.flatten([styles.radioButton, styles.radioButtonSelected])}
      >
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </View>
      <Text style={styles.radioButtonText}>{label}</Text>
    </TouchableOpacity>
  )
}

Radio.propTypes = {
  selected: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string,
}

export default Radio

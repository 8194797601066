import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, Share, Text, View } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import ActionsModal from 'src/components/ActionsModal'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import CheckCircleFilledIcon from 'src/icons/redesign/checkCircleFilled'
import styles from './styles'
import TermsAndConditionsModal from '../TermsAndConditionsModal'
import translations, { translate } from 'src/utils/translations/translations'

const isWeb = Platform.OS === 'web'

const data = {
  firstName: 'John',
  lastName: 'Doe',
  referralCode: 'ABC123',
} // TODO: Switch out to fetched data

type Props = {
  userHasAcceptedTerms: boolean
}

const ReferralShareButtons = ({ userHasAcceptedTerms }: Props) => {
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false)
  const [isConfirmationVisible, setisConfirmationVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(userHasAcceptedTerms)
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null)
  const timer = useRef<NodeJS.Timeout>()

  const { mixpanel } = useContext(MixpanelContext)

  const link = useMemo(() => {
    const { referralCode, firstName, lastName } = data
    const params = new URLSearchParams({
      referralCode,
      firstName,
      lastName,
    })
    return `${translate(translations.applyFormUrl)}/?${params.toString()}`
  }, [])

  useEffect(() => {
    return () => timer.current && clearTimeout(timer.current)
  }, [])

  const toggleIsActionsModalOpen = useCallback(
    () => setIsActionsModalOpen(prevState => !prevState),
    [setIsActionsModalOpen],
  )

  const toggleIsTermsAndConditionsModalOpen = useCallback(() => setShowModal(prevState => !prevState), [setShowModal])

  const sendEmailMessage = () =>
    openURLIfCan(
      `mailto:?subject=${translate(translations.referralShareInfoTitle)}&body=${translate(
        translations.referralShareInfo,
        { link: link },
      )}`,
    )

  const modalActions = useMemo(
    () => [
      {
        label: translate(translations.emailSend),
        onPress: () => {
          sendEmailMessage()
          toggleIsActionsModalOpen()
        },
      },
      {
        label: translate(translations.cancel),
        onPress: toggleIsActionsModalOpen,
      },
    ],
    [toggleIsActionsModalOpen],
  )

  const handleCopyLink = useCallback(() => {
    mixpanel?.track('Copy referral link', {
      teacherReferralLink: link,
    })

    Clipboard.setString(link)
    setisConfirmationVisible(true)
    timer.current = setTimeout(() => setisConfirmationVisible(false), 2000)
  }, [mixpanel])

  const handleShareAction = useCallback(async () => {
    mixpanel?.track('Share referral link', {
      teacherReferralLink: link,
    })

    if (isWeb) {
      toggleIsActionsModalOpen()
    } else {
      try {
        const result = await Share.share(
          {
            message: translate(translations.referralShareInfo, { link: link }),
          },
          {
            subject: translate(translations.referralShareInfoTitle),
          },
        )

        if (result.action === Share.sharedAction) {
          bugsnagActionBreadcrumb('Share referral link - action succeeded')
        } else if (result.action === Share.dismissedAction) {
          bugsnagActionBreadcrumb('Share referral link - user dismissed the action')
        }
      } catch (error) {
        bugsnagNotifyWithData('Share referral link - action errored', { error })
      }
    }
  }, [mixpanel, toggleIsActionsModalOpen])

  const handleButtonPress = useCallback(
    (action: () => void) => {
      if (hasAcceptedTerms) {
        action()
      } else {
        setPendingAction(() => action)

        toggleIsTermsAndConditionsModalOpen()
      }
    },
    [hasAcceptedTerms, toggleIsTermsAndConditionsModalOpen],
  )

  const handleAcceptTerms = useCallback(() => {
    setHasAcceptedTerms(true)
    toggleIsTermsAndConditionsModalOpen()
    if (pendingAction) {
      const timeoutId = setTimeout(() => {
        pendingAction()
        setPendingAction(null)
      }, 500)

      return () => clearTimeout(timeoutId)
    }
  }, [pendingAction, toggleIsTermsAndConditionsModalOpen])

  return (
    <>
      <ButtonNew
        onPress={() => handleButtonPress(handleShareAction)}
        size={buttonSize.lg}
        title={translate(translations.share)}
        variant={buttonVariants.containedDefault}
      />
      <ButtonNew
        onPress={() => handleButtonPress(handleCopyLink)}
        size={buttonSize.lg}
        title={translate(translations.copyLink)}
        variant={buttonVariants.outlinedDefault}
      />
      <View style={styles.copyConfirmationWrapper}>
        {isConfirmationVisible && (
          <>
            <Text style={styles.copyConfirmationText}>{translate(translations.linkCopied)}</Text>
            <CheckCircleFilledIcon size={14} />
          </>
        )}
      </View>
      <ActionsModal actions={modalActions} isVisible={isActionsModalOpen} onClose={toggleIsActionsModalOpen} />
      <TermsAndConditionsModal
        handleClose={toggleIsTermsAndConditionsModalOpen}
        handleSubmit={() => handleAcceptTerms()}
        hasAcceptedTerms={hasAcceptedTerms}
        showModal={showModal}
      />
    </>
  )
}

export default ReferralShareButtons

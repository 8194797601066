import React, { useContext, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'

import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { isGBSelector } from 'src/store/app/selectors'
import { logoutUser } from 'src/store/user/actions'
import MenuProfile from './MenuProfile'
import {
  ChatIcon,
  ContactSupportIcon,
  EditCalendarIcon,
  GiftIcon,
  HistoryIcon,
  InventoryIcon,
  LocationCityIcon,
  LocationOnIcon,
  LogoutIcon,
  PendingActionsIcon,
  ScheduleIcon,
  TuneIcon,
} from 'src/icons'
import { isUserApplicant } from 'src/store/user/selectors'
import { MenuParamList } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import AcademyMenuItem from './AcademyMenuItem'
import MenuItem from './MenuItem'
import useWarningMenu from 'src/hooks/useWarningMenu'
import { useAppDispatch } from 'src/hooks/reduxHooks'
import { useFeatureFlagsContext } from 'src/hooks/useFeatureFlags'
import globalStyles from 'src/global/globalStyles'

const Menu = () => {
  const [isLoading, setIsLoading] = useState(false)
  const isGB = useSelector(isGBSelector)

  const isApplicant = useSelector(isUserApplicant)

  const { applicationNotificationsCount, newMessage, unreported } = useWarningMenu()
  const navigation = useNavigation<StackNavigationProp<MenuParamList>>()
  const dispatch = useAppDispatch()
  const { mixpanel } = useContext(MixpanelContext)
  const { featureFlags } = useFeatureFlagsContext()

  const navigateToConfirmedJobs = () => navigation.navigate('AppointedBookings')
  const navigateToPendingInterests = () => navigation.navigate('Applications')
  const navigateToTimesheet = () => navigation.navigate('Timesheet')
  const navigateToHistory = () => navigation.navigate('History')
  const navigateToAvailability = () => navigation.navigate('Availability')
  const navigateToWorkPreferences = () => {
    mixpanel?.track('Job Preferences', { action: 'open' })
    navigation.navigate('Preferences')
  }
  const navigateToAreaPreferences = () => navigation.navigate('Area')
  const navigateToClientPreferences = () => navigation.navigate('Schools')
  const navigateToMessages = () => navigation.navigate('Chat')
  const navigateToSupport = () => navigation.navigate('Support')
  const navigateToReferrals = () => {
    mixpanel?.track('ReferAFriendOrFoe - Referrals', { action: 'open' })
    navigation.navigate('Referrals')
  }

  const logout = async () => {
    if (!isLoading) {
      setIsLoading(true)
      mixpanel?.track('Session')
      mixpanel?.reset()
      await dispatch(logoutUser())
    }
  }

  return (
    <ScrollView testID="scrollView" contentContainerStyle={{ ...styles.view, ...globalStyles.containerSize }}>
      <MenuProfile />

      <View style={styles.smallMT}>
        <MenuItem text={translate(translations.confirmedJobs)} Icon={InventoryIcon} onPress={navigateToConfirmedJobs} />
        <MenuItem
          badgeSize={applicationNotificationsCount}
          Icon={PendingActionsIcon}
          onPress={navigateToPendingInterests}
          text={translate(translations.pendingInterests)}
        />
      </View>
      <View style={styles.mediumMT}>
        <MenuItem
          badgeSize={unreported.length}
          Icon={ScheduleIcon}
          isDisabled={isApplicant}
          onPress={navigateToTimesheet}
          text={translate(translations.timesheetMenu)}
        />
        <MenuItem
          Icon={HistoryIcon}
          isDisabled={isApplicant}
          onPress={navigateToHistory}
          text={translate(translations.history)}
        />
      </View>
      <View style={styles.mediumMT}>
        <MenuItem
          Icon={EditCalendarIcon}
          isDisabled={isApplicant}
          onPress={navigateToAvailability}
          text={translate(translations.availability)}
        />
        <MenuItem text={translate(translations.workPreferences)} Icon={TuneIcon} onPress={navigateToWorkPreferences} />
        <MenuItem
          text={translate(translations.areaPreferences)}
          Icon={LocationOnIcon}
          onPress={navigateToAreaPreferences}
        />
        <MenuItem
          Icon={LocationCityIcon}
          isDisabled={isApplicant}
          onPress={navigateToClientPreferences}
          text={translate(translations.clientPreferences)}
        />
      </View>
      <View style={styles.mediumMT}>
        {featureFlags.referAFriend && (
          <MenuItem
            badgeSize={newMessage.length}
            Icon={GiftIcon}
            onPress={navigateToReferrals}
            subText={translate(translations.referralsMenuInfo)}
            text={translate(translations.referrals)}
          />
        )}
        <AcademyMenuItem />
        {isGB && (
          <MenuItem
            badgeSize={newMessage.length}
            Icon={ChatIcon}
            onPress={navigateToMessages}
            text={translate(translations.messages)}
          />
        )}
        <MenuItem text={translate(translations.support)} Icon={ContactSupportIcon} onPress={navigateToSupport} />
      </View>
      <View style={[styles.mediumMT, styles.logoutWrapper]}>
        <MenuItem
          Icon={LogoutIcon}
          isDisabled={isLoading}
          isLoading={isLoading}
          onPress={logout}
          testID="menu-logout"
          text={translate(translations.logOut)}
        />
      </View>
    </ScrollView>
  )
}

export default Menu

import React from 'react'
import { Formik } from 'formik'
import { Text, View } from 'react-native'

import { getReferralModalOptions } from './utils'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import InputRadioGroup from 'src/components/Form/InputRadioGroup/InputRadioGroupNew'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  closeModal: () => void
  educatorName: string
  showModal: boolean
}

const ReferralModal = ({ closeModal, educatorName, showModal }: Props) => {
  //todo - change when API is ready
  const initialValues = {
    variant: 'split',
  }

  const options = getReferralModalOptions()

  return (
    <NewModalWrapper
      backdropOpacity={0.5}
      containerStyle={styles.modalContent}
      isVisible={showModal}
      onBackdropPress={closeModal}
      onSwipeComplete={closeModal}
      propagateSwipe
      style={styles.modal}
      swipeDirection={['down']}
    >
      <View style={styles.container}>
        <View style={styles.drawer} />
        <Text style={styles.title}>{translate(translations.referralsModalTitle, { name: educatorName })}</Text>
        <Formik onSubmit={() => {}} initialValues={initialValues}>
          {({ dirty, errors, setFieldValue, touched, values }) => (
            <>
              <InputRadioGroup
                field={{
                  name: 'variant',
                  value: values.variant,
                }}
                form={{ errors, touched, setFieldValue }}
                highlightSelectedRow
                isRowPressable
                options={options}
                rowStyle={styles.radioInputOption}
              />
              <View style={styles.buttonsWrapper}>
                <ButtonNew
                  isLong
                  onPress={closeModal}
                  size={buttonSize.lg}
                  title={translate(translations.cancel)}
                  variant={buttonVariants.outlinedDefault}
                />
                <ButtonNew
                  disabled={!dirty}
                  isLong
                  size={buttonSize.lg}
                  title={translate(translations.select)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </>
          )}
        </Formik>
        <View style={styles.base} />
      </View>
    </NewModalWrapper>
  )
}

export default ReferralModal

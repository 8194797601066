import React, { useState } from 'react'
import { Keyboard, StyleSheet, Text, View } from 'react-native'

import { Formik, FormikHelpers } from 'formik'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { isGBSelector } from 'src/store/app/selectors'
import { openURLIfCan, trimAddress } from 'src/global/utils'
import { ukContactUrl } from 'src/global/constants'
import Api from 'src/utils/api'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ContactModal from 'src/scenes/Welcome/ResetPassword/components/ContactModal'
import translations, { translate } from 'src/utils/translations/translations'
import validationSchema from './validation'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'

import styles from 'src/scenes/Welcome/ResetPassword/styles'

type Props = {
  isError: boolean
  onSubmitCallback: () => void
  onSubmitError: () => void
  onSubmitSuccess: () => void
  removeError: () => void
}

type InitialValuesTypes = {
  email: string
}

const ResetPasswordForm = ({ isError, onSubmitCallback, onSubmitError, onSubmitSuccess, removeError }: Props) => {
  const [isContactModalVisible, setIsContactModalVisible] = useState(false)
  const [submittedValue, setSubmittedValue] = useState('')

  const isGB = useSelector(isGBSelector)

  const onChangeEffect = (text: string) => {
    if (submittedValue !== text) {
      removeError()
    }
  }

  const onSubmit = (values: InitialValuesTypes, { setSubmitting }: FormikHelpers<InitialValuesTypes>) => {
    bugsnagActionBreadcrumb('reset')

    const formattedEmail = encodeURIComponent(trimAddress(values?.email))

    Keyboard.dismiss()
    setSubmittedValue(values?.email)

    onSubmitCallback()
    setSubmitting(true)
    Api.get(`/sessions/forgot_password?email=${formattedEmail}`, {
      onSuccess: () => {
        setSubmitting(false)
        onSubmitSuccess()
      },
      onError: () => {
        onSubmitError()
        setSubmitting(false)
      },
    })
  }

  const closeContactModal = () => setIsContactModalVisible(false)

  const onContactButtonPress = () => {
    isGB ? openURLIfCan(ukContactUrl) : setIsContactModalVisible(true)
  }

  const initialValues: InitialValuesTypes = { email: '' }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, touched, values, setFieldTouched, setFieldValue, handleSubmit, isSubmitting, isValid }) => (
          <>
            <TextInputNew
              autoCapitalize="none"
              errorMessage={errors.email}
              isError={!isEmpty(errors.email) && touched.email}
              keyboardType="email-address"
              label={translate(translations.enterYourEmail)}
              onBlur={() => setFieldTouched('email', true)}
              onChangeEffect={onChangeEffect}
              onChangeText={text => setFieldValue('email', text)}
              onSubmitEditing={handleSubmit}
              required
              returnKeyType="done"
              size={TextInputSizeVariants.small}
              testID="reset-email"
              value={values.email}
            />

            <View style={StyleSheet.flatten([styles.mainContainer, global.isSM && styles.mainContainerSmall])}>
              <ButtonNew
                disabled={isSubmitting || isError || !isValid}
                onPress={handleSubmit}
                testID="resetPasswordButton"
                title={translate(translations.resetPassword)}
                variant={buttonVariants.containedDefault}
              />
              {isError ? (
                <>
                  <Text style={StyleSheet.flatten([styles.errorText, global.isSM && styles.errorTextSmall])}>
                    {translate(translations.resetPasswordError)}
                  </Text>
                  <ButtonNew
                    onPress={onContactButtonPress}
                    title={translate(translations.contactUs)}
                    variant={buttonVariants.outlinedDefault}
                  />
                </>
              ) : null}
            </View>
          </>
        )}
      </Formik>
      <ContactModal isVisible={isContactModalVisible} onClose={closeContactModal} />
    </>
  )
}

export default ResetPasswordForm

import React, { useState } from 'react'
import { Dimensions, Platform, ScrollView, Text, View } from 'react-native'

import { Chip, InfoSection } from './Components'
import { FriendOrFoe, GreenStroke, MoneyBagSvIcon, MoneyBagUKIcon, PersonWithCoffeeIcon } from 'src/icons'
import { isGBSelector } from 'src/store/app/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import infoOutlined from 'src/icons/redesign/infoOutlined'
import styles from './styles'
import TermsAndConditionsModal from '../components/TermsAndConditionsModal'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'
const { width } = Dimensions.get('window')
const customWidth = isWeb ? 500 : width

const HowItWorks = () => {
  const isGB = useAppSelector(isGBSelector)
  const MoneyBagIcon = isGB ? MoneyBagUKIcon : MoneyBagSvIcon

  const [showModal, setShowModal] = useState(false)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false) // TODO: Fetch and update user profile value for this data object.

  const handleSubmit = () => {
    setHasAcceptedTerms(true)
    setShowModal(false)
  }

  return (
    <ScrollView contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }} testID="scrollView">
      <View style={styles.headerImage}>
        <GreenStroke width={customWidth} />
      </View>
      <View style={styles.textSection}>
        <View style={styles.textSectionGroup}>
          <View style={styles.headerTextContainer}>
            <Chip text={translate(translations.howItWorksChipText)} />
            <Text style={styles.headerText}>{translate(translations.howItWorksHeading)}</Text>
          </View>
          <InfoSection
            heading={translate(translations.inviteOtherBrilliantEducatorsSubHeading)}
            textSections={[
              translate(translations.inviteOtherBrilliantEducatorsText),
              translate(translations.inviteOtherBrilliantEducatorsText2),
            ]}
            icon={<PersonWithCoffeeIcon size={70} height={54} />}
          />
          <InfoSection
            heading={translate(translations.chooseYourBonusSubHeading)}
            textSections={[translate(translations.chooseYourBonusText), translate(translations.chooseYourBonusText2)]}
            icon={<FriendOrFoe size={98} height={65} />}
          />
          <InfoSection
            heading={translate(translations.workFirstGetBonusAfterSubHeading)}
            textSections={[translate(translations.workFirstGetBonusAfterText)]}
            icon={<MoneyBagIcon size={49} />}
          />
          <View>
            <ButtonNew
              onPress={() => setShowModal(true)}
              RightIcon={infoOutlined}
              size={buttonSize.sm}
              title={translate(translations.termsAndConditions)}
              variant={buttonVariants.outlinedDefault}
            />
          </View>
        </View>
      </View>
      <TermsAndConditionsModal
        handleClose={() => setShowModal(false)}
        handleSubmit={handleSubmit}
        hasAcceptedTerms={hasAcceptedTerms}
        showModal={showModal}
      />
    </ScrollView>
  )
}

export default HowItWorks

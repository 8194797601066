import { Field, Formik, FormikProps } from 'formik'
import { Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import React, { useContext, useRef, useState } from 'react'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { createHealthQuestionnaire } from 'src/store/user/actions'
import { HealthQuestionnaireDataT } from 'src/store/user/actionTypes'
import { InputRadioGroupNew } from 'src/components/Form/InputRadioGroup'
import { markTaskAsCompleted } from 'src/scenes/Main/Profile/RequiredTasks/actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { ProfileTaskType } from 'src/scenes/Main/Profile/types'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import getValidationSchema from './validation'
import TextInputNew from 'src/components/Form/TextInputNew/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal from 'src/hooks/useBackModal'
import styles from './styles'

type Props = {
  closeForm: () => void
  task: ProfileTaskType
}

type InitialValuesType = {
  comment: ''
  hasHealthIssues: boolean | null
  mentallyAndphysicallyFit: boolean | null
}

const healthOptions = [
  {
    label: translate(translations.medicalQuestionnaireQ1A1),
    value: true,
  },
  {
    label: translate(translations.medicalQuestionnaireQ1A2),
    value: false,
  },
]

const fitOptions = [
  {
    label: translate(translations.medicalQuestionnaireQ2A1),
    value: true,
  },
  {
    label: translate(translations.medicalQuestionnaireQ2A2),
    value: false,
  },
]

const MedicalQuestionnaireForm = ({ task, closeForm }: Props) => {
  const [isError, setIsError] = useState(false)
  useBugsnagView('RequiredTask - MedicalQuestionnaireForm')

  const formikRef = useRef<FormikProps<InitialValuesType>>(null)
  const navigation = useNavigation<ProfileAndRecruitmentScreensProps<'MedicalQuestionnaire'>['navigation']>()

  const userId = useAppSelector(state => state.user.id) as string
  //@ts-ignore
  const closeScreen = useBackModal(navigation, formikRef)

  const { mixpanel } = useContext(MixpanelContext)

  const handleError = () => setIsError(true)
  const changeTaskStatus = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const submitForm = (values: Required<InitialValuesType>) => {
    bugsnagActionBreadcrumb('submit MedicalQuestionnaireForm', { id: userId, user: values })

    const dataToUpload = values.mentallyAndphysicallyFit ? values : omit(values, 'comment')
    createHealthQuestionnaire(dataToUpload as HealthQuestionnaireDataT, changeTaskStatus, handleError)
  }

  const initialValues: InitialValuesType = {
    hasHealthIssues: null,
    mentallyAndphysicallyFit: null,
    comment: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      //@ts-ignore
      innerRef={formikRef}
      validationSchema={getValidationSchema()}
    >
      {({ handleSubmit, setFieldTouched, setFieldValue, dirty, errors, isSubmitting, isValid, touched, values }) => (
        <>
          <Field
            component={InputRadioGroupNew}
            extraVerticalSpacing={false}
            isLarge
            label={translate(translations.medicalQuestionnaireQuestion1)}
            name="mentallyAndphysicallyFit"
            options={healthOptions}
          />
          <View style={styles.questionsDivider} />
          <Field
            component={InputRadioGroupNew}
            extraVerticalSpacing={false}
            isLarge
            label={translate(translations.medicalQuestionnaireQuestion2)}
            name="hasHealthIssues"
            options={fitOptions}
          />
          {values.hasHealthIssues === true ? (
            <>
              <Text style={styles.inputLabel}>{translate(translations.medicalQuestionnaireQuestion2b)}</Text>
              <TextInputNew
                errorMessage={errors.comment}
                isError={!isEmpty(errors.comment) && touched.comment}
                multiline
                onBlur={() => setFieldTouched('comment', true)}
                onChangeText={text => setFieldValue('comment', text)}
                placeholder={translate(translations.medicalQuestionnaireQuestion2bPlaceholder)}
                required
                size={TextInputSizeVariants.big}
                value={values.comment}
              />
            </>
          ) : null}
          <View style={styles.submitSection}>
            {isError ? (
              <View style={styles.alertWrapper}>
                <CompleteTaskError hideError={() => setIsError(false)} />
              </View>
            ) : null}
            <View style={styles.buttonsContainer}>
              <ButtonNew
                disabled={isSubmitting}
                isLong
                onPress={closeForm}
                title={translate(translations.cancel)}
                variant={buttonVariants.outlinedDefault}
              />
              <View style={styles.buttonDivider} />
              <ButtonNew
                disabled={!isValid || !dirty}
                isLong
                isSubmitting={isSubmitting}
                onPress={handleSubmit}
                title={translate(translations.submit)}
                variant={buttonVariants.containedDefault}
              />
            </View>
          </View>
        </>
      )}
    </Formik>
  )
}

export default MedicalQuestionnaireForm

import moment from 'moment'

import { AlertCardProps } from 'src/components/AlertCard'
import { alertVariants, alertColorScheme } from 'src/components/AlertCard/types'
import { ApplicationStatus, applicationStatus } from './applicationTypes'
import { TranslationSVT } from 'src/utils/translations/i18nTypes'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'

export const directApplications = [
  applicationStatus.userRequested,
  applicationStatus.directOffer,
  applicationStatus.flexibleOffer,
] as Partial<ApplicationStatus>[]

export const pendingApplications = [
  applicationStatus.applicationOffer,
  applicationStatus.directOffer,
  applicationStatus.flexibleOffer,
  applicationStatus.unconfirmedExpress,
  applicationStatus.userAssigned,
  applicationStatus.userRequested,
] as Partial<ApplicationStatus>[]

export const lockedOffers = [
  applicationStatus.unconfirmedExpress,
  applicationStatus.userAssigned,
] as Partial<ApplicationStatus>[]

type TGetJobAlertDataArguments = {
  offerStatus: ApplicationStatus
  schoolName?: string
  time?: string
  id: string
  isSickLeaves?: boolean
}

type TGetJobAlertDataBasedOnComplianceArguments = {
  isUserCompliant?: boolean
  isUserWorkPermitInvalid: boolean
  workPermitExpiresOn: string
}

type TIsUserWorkPermitValidArguments = {
  jobLatestDate?: string
  workPermitExpiresOn?: string | null
  workPermitRequired?: boolean
}

export const getJobAlertData = ({
  offerStatus,
  schoolName,
  time,
  id,
  isSickLeaves,
}: TGetJobAlertDataArguments): AlertCardProps | null => {
  const school = schoolName || translate(translations.school)

  if (isSickLeaves) {
    return {
      colorSchema: alertColorScheme.info,
      description: translate(translations.bookingAlertUnavailableDescription),
      id,
      title: translate(translations.bookingAlertUnavailableTitle),
      variant: alertVariants.standard,
    }
  }

  switch (offerStatus) {
    case applicationStatus.flexibleOffer:
      return {
        colorSchema: alertColorScheme.info,
        description: translate(translations.alertFlexibleOfferDescription, {
          school,
          startTime: time || '',
        }),
        id,
        title: translate(translations.alertFlexibleOfferTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.userRequested:
      return {
        colorSchema: alertColorScheme.info,
        description: translate(translations.alertUserRequestedDescription, { school }),
        id,
        title: translate(translations.alertUserRequestedTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.offerCancelled:
      return {
        colorSchema: alertColorScheme.error,
        description: translate(translations.alertOfferCanceledDescription, { school }),
        id,
        title: translate(translations.alertOfferCanceledTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.offerAppointToOtherTeacher:
      return {
        colorSchema: alertColorScheme.error,
        description: translate(translations.alertOfferNotAvailableDescription, { school }),
        id,
        title: translate(translations.alertOfferNotAvailableTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.userApplied:
      return {
        colorSchema: alertColorScheme.info,
        description: translate(translations.alertUserAppliedDescription),
        id,
        title: translate(translations.alertUserAppliedTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.userAssigned:
      return {
        colorSchema: alertColorScheme.success,
        description: undefined,
        id,
        title: translate(translations.alertPendingTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.unconfirmedExpress:
      return {
        colorSchema: alertColorScheme.info,
        description: translate(translations.alertUnconfirmedDescription),
        id,
        title: translate(translations.alertUnconfirmedTitle),
        variant: alertVariants.standard,
      }
    case applicationStatus.userAppointed:
      return {
        colorSchema: alertColorScheme.success,
        description: translate(translations.alertConfirmedDescription),
        id,
        title: translate(translations.alertConfirmedTitle),
        variant: alertVariants.standard,
      }
    default:
      return null
  }
}

export const isUserWorkPermitValid = ({
  jobLatestDate,
  workPermitExpiresOn,
  workPermitRequired,
}: TIsUserWorkPermitValidArguments) => {
  if (!workPermitRequired) return true
  if (workPermitRequired && workPermitExpiresOn === null) return false

  const jobLatestDateMoment = moment(jobLatestDate).set({ hour: 0, minute: 0, second: 0 })
  if (moment(workPermitExpiresOn).isSameOrAfter(jobLatestDateMoment)) return true

  return false
}

export const getJobAlertDataBasedOnCompliance = ({
  isUserCompliant,
  isUserWorkPermitInvalid,
  workPermitExpiresOn,
}: TGetJobAlertDataBasedOnComplianceArguments): AlertCardProps | null => {
  if (!isUserCompliant)
    return {
      colorSchema: alertColorScheme.warning,
      description: translate(translations.alertUserNotCompliant),
      testId: 'not-compliant-alert',
      variant: alertVariants.standard,
    }

  if (isUserWorkPermitInvalid) {
    return {
      colorSchema: alertColorScheme.warning,
      description: translate(translations.alertWorkPermitInvalid, {
        workPermitExpiresOn,
      }),
      testId: 'not-compliant-alert',
      variant: alertVariants.standard,
    }
  }

  return null
}

export const getScreenTitle = (status: ApplicationStatus) => {
  switch (status) {
    case applicationStatus.applicationOffer:
    case applicationStatus.directOffer:
    case applicationStatus.flexibleOffer:
    case applicationStatus.userRequested:
      return translate(translations.jobOfferHeaderAvailable)
    case applicationStatus.unconfirmedExpress:
      return translate((translations as TranslationSVT).jobOfferHeaderPendingExpress)
    case applicationStatus.userApplied:
    case applicationStatus.userAssigned:
      return translate(translations.jobOfferHeaderPending)
    case applicationStatus.offerAppointToOtherTeacher:
      return translate(translations.jobOfferHeaderNoAvailable)
    case applicationStatus.offerCancelled:
      return translate(translations.jobOfferHeaderCanceled)
    case applicationStatus.userAppointed:
      return translate(translations.jobOfferHeaderConfirmed)
  }
}

export const formatWorkPermitExpiredDate = (workPermitExpiresOn?: string | null) =>
  typeof workPermitExpiresOn === 'string'
    ? formatter.shortDateWithoutTimezone(workPermitExpiresOn)
    : `(${translate(translations.dateMissing)})`

import React, { useEffect, useState } from 'react'

import AnnouncementModal from 'src/components/AnnouncementModal'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../../package.json')

const ChangeLogModal = () => {
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false)

  const closeAnnouncementModal = () => setShowAnnouncementModal(false)

  const setCurrentAppVersionInLocalStorage = () => {
    // eslint-disable-next-line no-undef
    const storedVersion = localStorage.getItem('storedVersion')
    const shouldUpdateStorage = storedVersion !== version

    // eslint-disable-next-line no-undef
    if (shouldUpdateStorage) localStorage.setItem('storedVersion', version)
    setShowAnnouncementModal(shouldUpdateStorage)
  }

  useEffect(() => {
    setCurrentAppVersionInLocalStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AnnouncementModal closeAnnouncementModal={closeAnnouncementModal} showModal={showAnnouncementModal} />
}

export default ChangeLogModal

import { Linking, Platform } from 'react-native'
// @ts-ignore
import SafariView from 'src/utils/webAdapters/SafariViewAdapter'
// @ts-ignore
import moment from 'moment-timezone'

import { bugsnagActionBreadcrumb, bugsnagNotify } from 'src/utils/bugsnag'
import { setMomentLocale } from 'src/utils/config'
import { ClientDetails } from 'src/graphql/sharableTypes'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import countryLocalizationInfo from 'src/global/countryLocalizationInfo'
import navigationService from 'src/utils/navigationService'
import storage from 'src/mmkvStorage'
import translations, { translate } from 'src/utils/translations/translations'

export { moment }

let timezone = 'Europe/Stockholm'

export const parseWithTimezone = (timeStr: string) => moment.tz(timeStr, timezone)
export const timezoneByCountryCode = (countryCode: LocaleT) => {
  let newTimezone = ''

  if (!countryCode) {
    newTimezone = storage.getString('timezone') as string
  } else if (countryCode === 'se') newTimezone = countryLocalizationInfo.se.timezone
  else newTimezone = countryLocalizationInfo.gb.timezone

  return newTimezone
}

export const setTimezone = (zone: string, countryCode: LocaleT) => {
  timezone = zone?.length ? zone : timezoneByCountryCode(countryCode)
}

export const getTimezone = () => {
  setMomentLocale()
  return timezone
}

export const getTimezoneOffset = (date: string | Date) => moment(date).tz(timezone).utcOffset()

export const momentWithTimezone = (...args: any) => {
  setMomentLocale()
  return moment(...args).tz(timezone)
}

export const openURLIfCan = async (url: string) => {
  bugsnagActionBreadcrumb('openURLIfCan', { url })
  Linking.canOpenURL(url)
    .then(async supported => {
      if (!supported) {
        navigationService.navigate('Modal', {
          closeButtonText: translate(translations.close),
          description: translate(translations.cantHandleUrl),
          title: translate(translations.somethingWentWrong),
        })
      } else {
        if (Platform?.OS === 'ios' && url.split(':')[0].includes('http')) {
          SafariView.show({ url, fromBottom: true })
        } else {
          await Linking.openURL(url)
        }
      }
    })
    .catch(error => {
      bugsnagNotify(error)
      console.error(error)
    })
}

export const openTelUrl = async (url?: string) => {
  bugsnagActionBreadcrumb('openTelUrl', { url })
  await openURLIfCan(`tel:${url?.replace(/\s/g, '')}`)
}

export const openInterestForm = (countryCode: LocaleT) =>
  countryCode === 'gb' ? openURLIfCan('https://apply.humly.co.uk/') : openURLIfCan('https://apply.humly.io/')

export const regexes = {
  gbPhoneNumber: /^[\d]{9,}$/,
  gbPhoneNumberAllowEmpty: /^$|^[\d\W]{9,}$/,
  niNumber: /^[A-Za-z]{2}\d{6}[ABCD]$/,
  numbers: /^[1-9]+$/,
  numbersWithZero: /^[0-9]*$/,
  onlyLetters: /^[a-zA-Z\u00C0-\u017F\s-]+$/,
  onlyLettersStrict: /^[A-Za-z]+$/,
  onlyLettersWithExtraMarks: /^[\w'\-,.\u00C0-\u017F\s][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
  socialSecurityNumber: /^[\d\W]{12}$/,
  svPhoneNumber: /^[\d\W]{9,15}$/,
  svPhoneNumberAllowEmpty: /^$|^[\d\W]{9,15}$/,
  zipCode: /^[A-Za-z0-9 ]+$/,
}

export const trimAddress = (address: string) => (address || '').split('\n\n')[0]

type OpenMapUsingCoordinatesT = { coordinates: ClientDetails['coordinates']; label: string }

export const openMapUsingCoordinates = async ({ coordinates, label }: OpenMapUsingCoordinatesT) => {
  const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=', web: 'https://maps.google.com/?q=' })
  const latLng = `${coordinates[1]},${coordinates[0]}`
  const url = Platform.select({
    android: `${scheme}${latLng}(${label})`,
    ios: `${scheme}${label}@${latLng}`,
    web: `${scheme}${latLng}`,
  }) as string

  await openURLIfCan(url)
}

export const getCircularViewStyleObject = (size: number) => ({
  borderRadius: size / 2,
  height: size,
  width: size,
})

export const getLocale = (isGB: boolean) => (isGB ? 'en' : 'sv')

export const groupBy = (array: object[], keyGetter: (item?: any) => any, prev?: Map<string, []> | undefined) => {
  const map = new Map(prev ? [...prev] : null)
  array.forEach(item => {
    const keyValue = keyGetter(item)
    const collection = map.get(keyValue)
    if (!collection) {
      // @ts-ignore
      map.set(keyValue, [item])
      // @ts-ignore
    } else collection.push(item)
  })
  return map
}

export const capitalizeFirstLetter = (text = '') => text.charAt(0).toUpperCase() + text.slice(1)

import { createSelector } from 'reselect'
import { StoreType } from 'src/store'

const appStore = (state: StoreType) => state.app

export const getCountryCode = createSelector([appStore], app => app?.countryCode)
export const countryCodeSelector = createSelector([getCountryCode], countryCode => countryCode || 'gb')
export const isGBSelector = createSelector([countryCodeSelector], countryCode => countryCode === 'gb')
export const isSESelector = createSelector([countryCodeSelector], countryCode => countryCode === 'se')
export const getHiddenBookingAlertIds = createSelector([appStore], app => app?.hiddenBookingAlertIds || [])

export { default as BellOffIcon } from './bellOff'
export { default as BellOnIcon } from './bellOn'
export { default as BreakIcon } from './break'
export { default as CalendarDateIcon } from './calendarDate'
export { default as CancelledBookingIcon } from './cancelledBooking'
export { default as Chat } from './chat'
export { default as ChatPlaceholder } from './chatPlaceholder'
export { default as CheckFilledIcon } from './checkFilled'
export { default as ClockIcon } from './clock'
export { default as CloseFilledIcon } from './closeFilled'
export { default as ConversationIcon } from './conversation'
export { default as EditFilledIcon } from './editFilled'
export { default as EllipsisVerticalIcon } from './ellipsisVertical'
export { default as EmailIcon } from './email'
export { default as ExpressIcon } from './express'
export { default as FriendOrFoe } from '../icons/friendOrFoe'
export { default as GreenStroke } from '../icons/greenStroke'
export { default as InfoIcon } from './info'
export { default as InfoOutlineIcon } from './infoOutline'
export { default as JobsIcon } from './jobs'
export { default as LogoIcon } from './logo'
export { default as LoopsFilledIcon } from './loopsFilled'
export { default as MailFilledIcon } from './mailFilled'
export { default as MailFullFilledIcon } from './mailFullFilled'
export { default as MessageIcon } from './message'
export { default as MultiDayIcon } from './multiDay'
export { default as NotificationIcon } from './notification'
export { default as OneDayIcon } from './oneDay'
export { default as Person } from '../icons/person'
export { default as PhoneFilledIcon } from './phoneFilled'
export { default as PreferencesIcon } from './preferences'
export { default as ProfileIcon } from './profile'
export { default as QuestionIcon } from './question'
export { default as SalaryIcon } from './salary'
export { default as SearchFilledIcon } from './searchFilled'
export { default as SnapIcon } from './snap'
export { default as SnapTransparentIcon } from './snapTransparent'
export { default as SpilledCupIcon } from './spilledCup'
export { default as StarFilledIcon } from './starFilled'
export { default as StarIcon } from './star'
export { default as SupportCallIcon } from './supportCall'
export { default as SupportIcon } from './support'
export { default as SvIcon } from './sv'
export { default as ThumbsDownIcon } from './thumbsDown'
export { default as ThumbsUpIcon } from './thumbsUp'
export { default as TimeEditableIcon } from './timeEditable'
export { default as TimeExpressIcon } from './timeExpress'
export { default as TimeUnEditableIcon } from './timeUnEditable'
export { default as Trashcan } from './trashcan'
export { default as UKIcon } from './uk'
export { default as UserIcon } from './user'
export { default as VisibilityIcon } from './visibility'
export { default as VisibilityOffIcon } from './visibilityOff'
export { default as WaitingIcon } from './waiting'

// ---- redesign
export { default as AccountCircleIcon } from './redesign/accountCircle'
export { default as ArrowBackIosIcon } from './redesign/arrowBackIosRounded'
export { default as ArrowDropDown } from './redesign/arrowDropDown'
export { default as ArrowDropUp } from './redesign/arrowDropUp'
export { default as ArrowLeftIcon } from './redesign/arrowLeft'
export { default as ArrowRightIcon } from './redesign/arrowRight'
export { default as ArticleIcon } from './redesign/article'
export { default as AssignmentIcon } from './redesign/assignment'
export { default as AssignmentIndIcon } from './redesign/assignmentInd'
export { default as AttachMoneyIcon } from './redesign/attachMoney'
export { default as CalendarCardIcon } from './redesign/calendarCard'
export { default as CalendarCardMultidayIcon } from './redesign/calendarCardMultiday'
export { default as CalendarIcon } from './redesign/calendarIcon'
export { default as CalendarMonthIcon } from './redesign/calendarMonth'
export { default as CameraIcon } from './redesign/camera'
export { default as ChatIcon } from './redesign/chat'
export { default as CheckboxBlankIcon } from './redesign/checkboxBlank'
export { default as CheckboxIcon } from './redesign/checkbox'
export { default as CheckCircleFilledIcon } from './redesign/checkCircleFilled'
export { default as CheckCircleIcon } from './redesign/checkCircleRounded'
export { default as CheckCircleOutlinedIcon } from './redesign/checkCircleOutlined'
export { default as CheckIcon } from './redesign/check'
export { default as ChevronLeftIcon } from './redesign/chevronLeft'
export { default as ChevronRightIcon } from './redesign/chevronRight'
export { default as CloseIcon } from './redesign/closeRounded'
export { default as CoffeIcon } from './redesign/coffeeIcon'
export { default as ContactMailIcon } from './redesign/contactMail'
export { default as ContactSupportIcon } from './redesign/contactSupport'
export { default as ContentPasteSearchIcon } from './redesign/contentPasteSearch'
export { default as DeleteFilledIcon } from './redesign/deleteFilled'
export { default as DeleteOutlineIcon } from './redesign/deleteOutline'
export { default as DescriptionIcon } from './redesign/description'
export { default as DoubleArrowIcon } from './redesign/doubleArrow'
export { default as DownloadIcon } from './redesign/download'
export { default as EditCalendarIcon } from './redesign/editCalendar'
export { default as EditIcon } from './redesign/edit'
export { default as EditLocationIcon } from './redesign/editLocation'
export { default as EnvelopeIcon } from './redesign/envelopeIcon'
export { default as ErrorOutlinedIcon } from './redesign/errorOutlined'
export { default as EventAvailableIcon } from './redesign/eventAvailable'
export { default as ExpandLessIcon } from './redesign/expandLess'
export { default as ExpandMoreIcon } from './redesign/expandMore'
export { default as FactCheckedIcon } from './redesign/factChecked'
export { default as FeedbackIcon } from './redesign/feedback'
export { default as FreeBreakfastRoundedIcon } from './redesign/freeBreakfastRounded'
export { default as FriendOrFoeFullBodyIcon } from './redesign/friendOrFoeFullBody'
export { default as GiftIcon } from './redesign/gift'
export { default as HealingIcon } from './redesign/healing'
export { default as HistoryIcon } from './redesign/history'
export { default as HouseRoundedIcon } from './redesign/houseRounded'
export { default as HumlyIcon } from './redesign/humlyIcon'
export { default as InfoOutlinedIcon } from './redesign/infoOutlined'
export { default as InventoryIcon } from './redesign/inventory'
export { default as LineDiagonal } from './redesign/lineDiagonal'
export { default as LocationCityIcon } from './redesign/locationCity'
export { default as LocationOnIcon } from './redesign/locationOn'
export { default as LockIcon } from './redesign/lock'
export { default as LogoutIcon } from './redesign/logout'
export { default as MenuIcon } from './redesign/menu'
export { default as MoneyBagSvIcon } from './redesign/moneyBagSv'
export { default as MoneyBagUKIcon } from './redesign/moneyBagUK'
export { default as NewReleaseRoundedIcon } from './redesign/newReleaseRounded'
export { default as NotificationsIcon } from './redesign/notifications'
export { default as OpenInNewIcon } from './redesign/openInNew'
export { default as PaymentsIcon } from './redesign/payments'
export { default as PendingActionsIcon } from './redesign/pendingActions'
export { default as PersonRoundedIcon } from './redesign/personRounded'
export { default as PersonSearchIcon } from './redesign/personSearch'
export { default as PersonWithCoffeeIcon } from './redesign/personWithCoffee'
export { default as PhoneRoundedIcon } from './redesign/phoneRounded'
export { default as PsychologyRoundedIcon } from './redesign/psychologyRounded'
export { default as QuestionMarkIcon } from './redesign/questionMarkIcon'
export { default as RestaurantRoundedIcon } from './redesign/restaurantRounded'
export { default as ScheduleIcon } from './redesign/schedule'
export { default as SchoolIcon } from './redesign/school'
export { default as SlideShowIcon } from './redesign/slideshow'
export { default as SportsScoreIcon } from './redesign/sportsScore'
export { default as SuitcaseIcon } from './redesign/suitcaseIcon'
export { default as TuneIcon } from './redesign/tune'
export { default as UploadIcon } from './redesign/upload'
export { default as VerifiedIcon } from './redesign/verified'
export { default as VerifiedUserIcon } from './redesign/verifiedUser'
export { default as WarningAmberIcon } from './redesign/warningAmber'
export { default as WarningIcon } from './redesign/warning'
export { default as WorkIcon } from './redesign/work'

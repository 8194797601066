import React, { useCallback } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import { JobsNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ListCard from 'src/components/ListCard'
import { isGBSelector } from 'src/store/app/selectors'
import { statuses } from 'src/components/ListCard/utils'

type Props = {
  expressCount: number
  isExpressAndLongTermLoading: boolean
  isExpressExists: boolean
  longTermCount: number
}

const LongTermAndExpressSection = ({
  expressCount,
  isExpressAndLongTermLoading,
  isExpressExists,
  longTermCount,
}: Props) => {
  const userName = useAppSelector(state => state.user.attributes?.firstName) as string
  const desiredWorkloads = useAppSelector(state => state.user?.attributes?.desiredWorkloads) || []
  const isGB = useSelector(isGBSelector)
  const navigation = useNavigation<JobsNavigatorScreensProps<'Requests'>['navigation']>()

  const isLongTermExists = isGB
    ? desiredWorkloads.includes('long_term') || desiredWorkloads.includes('permanent')
    : desiredWorkloads.includes('long_term')

  const itemsExists = isExpressExists || isLongTermExists || isExpressAndLongTermLoading

  const showAllOffers = useCallback(() => navigation.navigate('LongTermOfferList'), [navigation])
  const showAllExpress = useCallback(() => navigation.navigate('ExpressOfferList'), [navigation])

  const longTermExtraStyles = { marginRight: isExpressExists ? 10 : 0 }

  const content = isExpressAndLongTermLoading ? (
    <LoadingIndicator size="small" testID="express-and-long-term-loading" />
  ) : (
    <View style={styles.cardsContainer}>
      {isLongTermExists && (
        <ListCard
          extraStyles={longTermExtraStyles}
          jobsNumber={longTermCount}
          onPress={showAllOffers}
          status={statuses.longTerm}
        />
      )}
      {isExpressExists && <ListCard status="express" jobsNumber={expressCount} onPress={showAllExpress} />}
    </View>
  )

  return (
    <View style={StyleSheet.flatten([styles.containerMargin, itemsExists ? styles.container : {}])}>
      <Text style={styles.header}>{translate(translations.hi, { userName })}</Text>
      {itemsExists && (
        <>
          <Text style={styles.subheader}>{translate(translations.checkLongTermJob)}</Text>
          <View style={styles.bottomSection}>
            <View style={styles.cardsWrapper}>{content}</View>
          </View>
        </>
      )}
    </View>
  )
}

export default LongTermAndExpressSection

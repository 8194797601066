import { StyleSheet } from 'react-native'

import { padding, colorsNew, typography, paddingNew } from 'src/styles'

export default StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: padding.lg,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  applicantGotoRecruitmentButton: {
    marginTop: paddingNew.base,
  },
  emptyMessage: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
})

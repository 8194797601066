import * as Yup from 'yup'

import { regexes } from 'src/global/utils'
import translations, { translate } from 'src/utils/translations/translations'
import { TranslationGBT, TranslationSVT } from 'src/utils/translations/i18nTypes'

const common = {
  birthDate: Yup.string().required(translate(translations.blank)),
  email: Yup.string().email(translate(translations.enterValidEmail)).required(translate(translations.blank)),
}

const validationSchemaGB = Yup.object().shape({
  ...common,
  firstName: Yup.string()
    .min(2, translate(translations.emergencyNameError))
    .matches(regexes.onlyLettersWithExtraMarks, translate(translations.errorLettersOnly))
    .required(translate(translations.enterMandatoryFirstName)),
  homeAddress: Yup.object({
    city: Yup.string()
      .matches(regexes.onlyLetters, translate((translations as TranslationGBT).errorLettersOnlyStrict))
      .required(translate(translations.blank)),
    county: Yup.string().nullable(),
    street: Yup.string().required(translate(translations.blank)),
    zip: Yup.string()
      .nullable()
      .required(translate(translations.blank))
      .test({
        name: 'zip',
        test: function test(value: string) {
          return value && value.length > 5 && value.length < 9 && regexes.zipCode.test(value)
        },
        message: () => translate(translations.zipCodeError),
      } as Yup.TestConfig),
  }),
  landPhone: Yup.string()
    .nullable()
    .test({
      name: 'landPhone',
      test: value => (value ? regexes.gbPhoneNumber.test(value) : true),
      message: () => translate(translations.phoneError),
    }),
  lastName: Yup.string()
    .min(2, translate(translations.emergencyNameError))
    .matches(regexes.onlyLettersWithExtraMarks, translate(translations.errorLettersOnly))
    .required(translate(translations.enterMandatoryLastName)),
  nationality: Yup.string().nullable().required(translate(translations.enterMandatoryNationality)),
  phone: Yup.string()
    .required(translate(translations.blank))
    .test({
      name: 'phone',
      test: (value: string) => value && regexes.gbPhoneNumber.test(value),
      message: () => translate(translations.phoneError),
    } as Yup.TestConfig),
  salutation: Yup.string().nullable().matches(regexes.onlyLetters, translate(translations.errorLettersOnly)),
  title: Yup.string().required(translate(translations.blank)),
})

const validationSchemaSv = Yup.object().shape({
  ...common,
  firstName: Yup.string()
    .matches(regexes.onlyLettersWithExtraMarks, translate((translations as TranslationSVT).enterValidFirstName))
    .required(translate(translations.blank)),
  homeAddress: Yup.object({
    city: Yup.string()
      .matches(regexes.onlyLetters, translate((translations as TranslationSVT).enterValidCity))
      .required(translate(translations.blank)),
    street: Yup.string().required(translate(translations.blank)),
    zip: Yup.string()
      .nullable()
      .required(translate(translations.blank))
      .test({
        name: 'zip',
        test: function test(value: string) {
          return value && value.length > 4 && regexes.numbersWithZero.test(value)
        },
        message: () => translate(translations.zipCodeError),
      } as Yup.TestConfig),
  }),
  lastName: Yup.string()
    .matches(regexes.onlyLettersWithExtraMarks, translate((translations as TranslationSVT).enterValidLastName))
    .required(translate(translations.blank)),
  phone: Yup.string()
    .required(translate(translations.blank))
    .test({
      name: 'phone',
      test: (value: string) => value && regexes.svPhoneNumber.test(value),
      message: () => translate(translations.phoneError),
    } as Yup.TestConfig),
})

const getValidationSchema = (isGB: boolean) => (isGB ? validationSchemaGB : validationSchemaSv)

export default getValidationSchema

import React, { useState } from 'react'
import { ImagePickerResponse } from 'react-native-image-picker'
import { openSettings } from 'src/utils/webAdapters/RNPermissionsAdapter'
import { useNavigation } from '@react-navigation/native'
import { View, Image } from 'react-native'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { CameraIcon } from 'src/icons'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import IconButton from 'src/components/Buttons/IconButton/IconButton'
import images from 'src/global/images'
import ProfilePictureModal from './ProfilePictureModal'
import translations, { translate } from 'src/utils/translations/translations'
import styles, { SIZE } from './styles'
import EmptyProfilePicturePlaceholder from 'src/components/EmptyProfilePicturePlaceholder'

const ProfilePictureUploader = () => {
  const profilePictureUrl = useAppSelector(state => state.user.attributes?.profilePictureUrl)
  const userId = useAppSelector(state => state.user.id)!

  const [showPictureModal, setShowPictureModal] = useState(false)
  const [updatedPicture, setUpdatedPicture] = useState<string | null>()
  const [isLoading, setIsLoading] = useState(false)

  const openWarningModal = () => {
    setShowPictureModal(true)
  }

  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  const showGoToSettingModal = () => {
    bugsnagActionBreadcrumb('show setting modal')
    setTimeout(
      () =>
        navigation.navigate('Modal', {
          title: translate(translations.permissionDenied),
          description: translate(translations.permissionDeniedInfo),
          onConfirm: openSettings,
          closeButtonText: translate(translations.goToSettings),
        }),
      300,
    )
  }

  const imagePickerCallback = (response: ImagePickerResponse) => {
    const { didCancel, assets, errorCode } = response

    setIsLoading(true)
    if (errorCode === 'camera_unavailable') {
      setIsLoading(false)

      navigation.navigate('Modal', {
        closeButtonText: translate(translations.close),
        description: translate(translations.cameraNotAvailable),
        title: translate(translations.somethingWentWrong),
      })
      return
    }

    if (!didCancel && assets?.length) {
      const { uri, fileName } = assets[0]
      bugsnagActionBreadcrumb('image picker on onboarding view', { fileName })

      let profilePictureFileName = fileName
      if (!profilePictureFileName && uri) {
        const uriSegments = uri.split('/')
        profilePictureFileName = uriSegments[uriSegments.length - 1]
      }
      setUpdatedPicture(uri)
      return dispatch(
        // @ts-ignore
        saveUser({
          fileName: profilePictureFileName,
          id: userId,
          uri: uri,
          onSuccess: () => {
            setIsLoading(false)
            setUpdatedPicture(null)
          },
          onError: () => setIsLoading(false),
        }),
      )
    }
    setIsLoading(false)
  }
  const imageSource =
    profilePictureUrl || updatedPicture ? { uri: updatedPicture || profilePictureUrl } : images.backgroundDark

  return (
    <View>
      {profilePictureUrl ? (
        <Image source={imageSource} style={styles.profilePicture} resizeMethod="resize" />
      ) : (
        <EmptyProfilePicturePlaceholder wrapperSize={SIZE} />
      )}
      <View style={styles.button}>
        <IconButton size="lg" variant="filled" Icon={CameraIcon} onPress={openWarningModal} isSubmitting={isLoading} />
      </View>
      <ProfilePictureModal
        showModal={showPictureModal}
        onClose={() => {
          setShowPictureModal(false)
        }}
        onPermissionDenied={showGoToSettingModal}
        imagePickerCallback={imagePickerCallback}
        navigation={navigation}
      />
    </View>
  )
}

export default ProfilePictureUploader

import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    marginBottom: paddingNew.sm,
  },
  container: {
    display: 'flex',
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.base,
  },
  info: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.xxs,
  },
  referralsWrapper: {
    gap: paddingNew.sm,
    marginBottom: paddingNew.base,
    marginTop: paddingNew.sm,
  },
  title: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    lineHeight: 16,
  },
})

export default styles

import React, { useContext, useMemo, useRef, useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { ScrollView, Text, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserEducation } from 'src/store/user/selectors'
import { getYearsOptions } from 'src/components/Form/utils'
import { InputSelectNew } from 'src/components/Form'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal from 'src/hooks/useBackModal'
import validationSchema from './validationSchema'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'HighSchoolEducation'>
type InitialValuesType = {
  graduationYear: string
  name: string
  subject: string
}

const HighSchoolEducation = ({ navigation, route }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - HighSchoolEducation')

  const formikRef = useRef<FormikProps<InitialValuesType>>(null)
  const dispatch = useAppDispatch()
  const userId = useAppSelector(state => state.user.id) as string
  const userEducations = useAppSelector(getUserEducation)

  const [isError, setIsError] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const userHighSchool = useMemo(() => userEducations.find(item => item?.level === 'highSchool'), [userEducations])

  const initialValues = pick(userHighSchool, ['graduationYear', 'name', 'subject'])

  const closeScreen = useBackModal(navigation, formikRef)
  const handleError = () => setIsError(true)
  const updateTaskStatus = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const submitForm = (values: InitialValuesType, form: FormikHelpers<InitialValuesType>) => {
    bugsnagActionBreadcrumb('submit', { id: userId, user: values })
    const newStudentEducation = { level: 'highSchool', ...userHighSchool, ...values }
    const updatedStudentEducations = [
      newStudentEducation,
      ...userEducations.filter(education => education?.id !== userHighSchool?.id),
    ]

    setIsError(false)
    dispatch(
      //@ts-ignore
      saveUser({
        id: userId,
        onError: () => {
          handleError()
          form.setSubmitting(false)
        },
        onSuccess: isCompleted ? closeScreen : updateTaskStatus,
        stringifyData: true,
        user: { studentEducations: updatedStudentEducations },
      }),
    )
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={globalStyles.containerSize}
      testID="scrollView"
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        {({ dirty, errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => (
          <View style={styles.container}>
            <StatusInfo status={status} timeToComplete={timeToComplete} />
            <TextInputNew
              disabled={isCompleted}
              errorMessage={errors.subject}
              isError={!isEmpty(errors.subject) && touched.subject}
              label={translate(translations.highSchoolProgram)}
              onBlur={() => setFieldTouched('subject', true)}
              onChangeText={text => setFieldValue('subject', text)}
              required
              size={TextInputSizeVariants.medium}
              value={values.subject}
            />
            <TextInputNew
              disabled={isCompleted}
              errorMessage={errors.name}
              isError={!isEmpty(errors.name) && touched.name}
              label={translate(translations.highSchoolName)}
              onBlur={() => setFieldTouched('name', true)}
              onChangeText={text => setFieldValue('name', text)}
              required
              size={TextInputSizeVariants.medium}
              value={values.name}
            />
            <InputSelectNew
              disabled={isCompleted}
              errorMessage={errors.graduationYear}
              isError={!isEmpty(errors.graduationYear) && touched.graduationYear}
              label={translate(translations.universityGraduation)}
              onBlur={() => setFieldTouched('graduationYear', true)}
              onValueChange={(value: string) => setFieldValue('graduationYear', value)}
              required
              value={values.graduationYear}
              values={getYearsOptions(51, 0)}
            />
            <View style={styles.submitSection}>
              {isError ? (
                <View style={styles.alertWrapper}>
                  <CompleteTaskError hideError={() => setIsError(false)} />
                </View>
              ) : null}
              <View style={styles.buttonContainer}>
                <ButtonNew
                  disabled={!isValid || !dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                  onPress={handleSubmit}
                  size={buttonSize.lg}
                  title={translate(translations.saveChanges)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </View>
            <Text style={styles.info}>{translate(translations.highSchoolInfo)}</Text>
          </View>
        )}
      </Formik>
    </ScrollView>
  )
}

export default HighSchoolEducation

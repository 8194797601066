import React, { useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { getReferAnimationVariant } from '../utils'
import { isGBSelector } from 'src/store/app/selectors'
import { MoneyBagSvIcon, MoneyBagUKIcon } from 'src/icons'
import { ReferralShareButtons } from '../components'
import { referralsStates } from './constants'
import { useAppSelector } from 'src/hooks/reduxHooks'
import LoadingSection from './components/LoadingSection'
import MoneyInfo, { moneyInfoVariant } from './components/MoneyInfo'
import ReferralSection from './components/ReferralSection'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

//mockedData - todo (remove)
const referralsList = [
  referralsStates.paidOut,
  referralsStates.educatorAccepted,
  referralsStates.educatorInRecruitment,
  referralsStates.educatorLeftHumly,
]

const referrals = {
  [referralsStates.paidOut]: [
    {
      id: '1',
      applicantName: 'Julia Nyberg',
      bonusDate: '2024-05-20',
      variant: 'take',
      paid: true,
    },
    {
      id: '2',
      applicantName: 'Ludvig Andersson',
      bonusDate: '2024-05-25',
      variant: 'give',
      paid: true,
    },
    {
      id: '3',
      applicantName: 'Kristoffer Persson',
      bonusDate: '2024-04-01',
      variant: 'split',
      paid: true,
    },
  ],
  [referralsStates.educatorAccepted]: [
    {
      id: '4',
      applicantName: 'Hanna White',
      applied: '2023-11-20',
      variant: 'split',
      paid: true,
    },
    {
      id: '5',
      applicantName: 'Hanna White',
      applied: '2023-01-01',
      variant: 'take',
      paid: false,
    },
  ],
  [referralsStates.educatorInRecruitment]: [
    {
      id: '6',
      applicantName: 'Hanna White',
      applied: '2023-12-14',
      variant: 'split',
      paid: false,
    },
    {
      id: '7',
      applicantName: 'Hanna White',
      applied: '2024-02-14',
      variant: 'take',
      paid: false,
    },
  ],
  [referralsStates.educatorLeftHumly]: [
    {
      id: '8',
      applicantName: 'Hanna White',
      applied: '2023-12-01',
      paid: false,
    },
    {
      id: '9',
      applicantName: 'Hanna White',
      applied: '2023-12-14',
      paid: false,
    },
    {
      id: '10',
      applicantName: 'Hanna White',
      applied: '2023-12-31',
      paid: false,
    },
  ],
}

//todo - remove when API is ready
const givenMoney = 0
const earnedMoney = 0

const ReferralsList = () => {
  //todo - remove when API is ready
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000)
  }, [])

  const isGB = useAppSelector(isGBSelector)
  const MoneyBagIcon = isGB ? MoneyBagUKIcon : MoneyBagSvIcon

  const isNoReferrals = false
  const animationVariant = getReferAnimationVariant({ earnedMoney, givenMoney })

  const playAnimation = !isLoading && !isNoReferrals

  const renderReferrals = () => {
    if (isLoading) {
      return <LoadingSection />
    }

    if (isNoReferrals) {
      return (
        <>
          <View style={styles.emptyViewWrapper}>
            <MoneyBagIcon size={67} />
            <Text style={styles.info}>{translate(translations.emptyReferralsTitle)}</Text>
            <Text style={styles.subInfo}>{translate(translations.emptyReferralsInfo)}</Text>
          </View>
          <View style={styles.buttonsWrapper}>
            <ReferralShareButtons userHasAcceptedTerms={false} />
          </View>
        </>
      )
    }

    return referralsList.map(referralSection => (
      <ReferralSection key={referralSection} section={referralSection} sectionReferrals={referrals[referralSection]} />
    ))
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.moneyInfoWrapper}>
        <MoneyInfo
          amountOfMoney={givenMoney}
          animationVariant={animationVariant}
          isLoading={isLoading}
          moneyRise={0}
          playAnimation={playAnimation}
          variant={moneyInfoVariant.given}
        />
        <MoneyInfo
          amountOfMoney={earnedMoney}
          animationVariant={animationVariant}
          isLoading={isLoading}
          moneyRise={0}
          playAnimation={playAnimation}
          variant={moneyInfoVariant.earned}
        />
      </View>
      {renderReferrals()}
    </ScrollView>
  )
}

export default ReferralsList

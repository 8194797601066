import React from 'react'
import { StyleSheet, Text } from 'react-native'
import get from 'lodash/get'

import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  errorMessageStyle?: object
  errors: object
  name: string
}

const Error = ({ errorMessageStyle, errors, name }: Props) => {
  const fieldError = get(errors, name, '')
  const errorMessage = translations[fieldError] ? translate(translations[fieldError]) : fieldError

  return <Text style={StyleSheet.flatten([styles.errorText, errorMessageStyle])}>{errorMessage}</Text>
}

export default Error
